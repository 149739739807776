import { Location } from 'history';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

export interface ModalRoute {
  route: ModalName;
  component: React.FC<{ modalParameters?: unknown }>;
  backgroundIsOptional?: boolean;
}

export interface LocationState {
  modalParameters?: undefined;
  background: Location<unknown>;
}

export type ModalsParamList = {
  availability: LocationState;
  qualification: LocationState;
  skill: LocationState;
  location: LocationState;
  diploma: LocationState;
  company: LocationState;
  'driving-licence': LocationState;
  language: LocationState;
  habilitation: LocationState;
  caces: LocationState;
  service: LocationState;
  'potential-position': LocationState;
  keyword: LocationState;
  'reassignCgc/:id': LocationState;
  'candidate/:candidateId/resume': LocationState;
  results: LocationState;
};

export type ModalName = keyof ModalsParamList;
export type ModalNameParamExtending<BaseState> = {
  [K in ModalName]: ModalsParamList[K] extends BaseState ? K : never;
}[ModalName];

export function useModal<T extends ModalName = ModalName>(modalName: T) {
  const history = useHistory();
  return {
    open: (state: ModalsParamList[T]) => {
      history.push({
        pathname: modalName,
        state: state,
      });
    },
  };
}

export function useModalWithLocation<
  T extends ModalNameParamExtending<LocationState> = ModalNameParamExtending<LocationState>
>(modalName: T) {
  const location = useLocation();
  const { open } = useModal(modalName);
  return {
    open: (state?: Omit<ModalsParamList[T], 'background'>) => {
      open({ background: location, ...state });
    },
  };
}
