import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory, useLocation } from 'react-router';

export const useOpenCandidateResume = () => {
  const history = useHistory();
  const location = useLocation();
  return {
    openCandidateResume: ({ candidateId }: { candidateId?: string }) => {
      if (candidateId === undefined) {
        toast.error('Erreur lors de la création de la synthèse de cet intérimaire');
        return;
      }
      history.push({
        pathname: `/candidate/${candidateId}/resume`,
        state: {
          background: location,
        },
      });
    },
  };
};
