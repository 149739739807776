import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService, CandidateSkillsEntity, QualificationEntity } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useAddQualificationToCandidate = (
  candidateId: string,
  extraOptions?: UseMutationOptions<
    void,
    AxiosError,
    string,
    {
      previousSkills?: CandidateSkillsEntity;
      previousSuggestedQualifications?: QualificationEntity[];
    }
  >
) => {
  const brand = useSelector(getCurrentBrand);
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.addQualificationToCandidate, candidateId],
    async qualificationId => {
      return await CandidatesService.candidatesControllerAddQualificationToCandidate({
        candidateId,
        qualificationId,
        brandCode: brand?.brandCodeApiHeader,
      });
    },
    {
      ...extraOptions,
      onMutate: qualificationId => {
        const previousSkills = queryClient.getQueryData<CandidateSkillsEntity>([
          QueryKeys.fetchCandidateSkills,
          candidateId,
        ]);
        const previousSuggestedQualifications = queryClient.getQueryData<QualificationEntity[]>([
          QueryKeys.fetchCandidateSuggestedQualifications,
          candidateId,
        ]);

        queryClient.setQueryData<CandidateSkillsEntity>(
          [QueryKeys.fetchCandidateSkills, candidateId],
          skills => ({
            ...skills,
            qualifications: (skills?.qualifications ?? []).concat(
              previousSuggestedQualifications?.filter(
                qualification => qualification.id === qualificationId
              ) ?? []
            ),
          })
        );
        queryClient.setQueryData<QualificationEntity[]>(
          [QueryKeys.fetchCandidateSuggestedQualifications, candidateId],
          suggestedQualifications =>
            suggestedQualifications?.filter(qualification => qualification.id !== qualificationId)
        );
        return { previousSuggestedQualifications, previousSkills };
      },
      onError: async (error, variables, context) => {
        queryClient.setQueryData<CandidateSkillsEntity>(
          [QueryKeys.fetchCandidateSkills, candidateId],
          context?.previousSkills
        );
        queryClient.setQueryData<QualificationEntity[]>(
          [QueryKeys.fetchCandidateSuggestedQualifications, candidateId],
          context?.previousSuggestedQualifications
        );

        extraOptions?.onError?.(error, variables, context);
      },
      onSettled: async (data, error, variables, context) => {
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateR1, candidateId]);
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateSkills, candidateId]);
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateBusinesses, candidateId]);
        await queryClient.invalidateQueries([
          QueryKeys.fetchCandidateSuggestedQualifications,
          candidateId,
        ]);
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateDetails, candidateId]);
        await queryClient.invalidateQueries([
          QueryKeys.fetchCandidateSuggestedQualifications,
          candidateId,
        ]);

        extraOptions?.onSettled?.(data, error, variables, context);
      },
    }
  );
};
