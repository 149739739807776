import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../RootReducer';

import { MissionsState } from './Types';

const getMissionsState = (state: RootState) => state.missions;

export const getSelectedMissionId = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.selectedMissionId
);
