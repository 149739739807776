import {
  Edit,
  License,
  OtherHorizontal,
  People,
  Timing,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Avatar,
  Button,
  CardClickable,
  Checkbox,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';

import { CANDIDATE_STATUS_LABEL } from 'src/Containers/CandidateResults/CandidateResults.types';
import { formatCity } from 'src/Utils/formatCity';
import { formatName } from 'src/Utils/formatName';

import ScoringIndicator from '../../ScoringIndicator';
import { CandidateActions } from '../CandidateActions';
import { CandidateCardProps } from '../CandidateCard.types';

import styles from './HorizontalCandidateCard.module.scss';

export const HorizontalCandidateCard = ({
  candidate,
  isSelected,
  isSelecting,
  onClick,
  attachCandidate,
}: Omit<CandidateCardProps, 'vertical'>) => {
  const contractEndDate = moment().isBefore(candidate.availabilityDate)
    ? moment(candidate.availabilityDate)
    : undefined;

  return (
    <CardClickable color="white" className={styles.card} onClick={onClick} focus={isSelected}>
      <div
        className={classnames(
          styles.header,
          candidate.candidateStatus === CANDIDATE_STATUS_LABEL['Intérimaire']
            ? styles.navy
            : candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim']
            ? styles.yellow
            : styles.greenish
        )}
      >
        {isSelecting && (
          <div className={styles.checkbox}>
            <Checkbox checked={isSelected} />
          </div>
        )}
        <Avatar
          size="medium"
          color={
            candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'] ? 'white' : 'blue'
          }
          content={candidate}
        />
        <div
          data-theme={
            candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'] ? 'light' : 'dark'
          }
        >
          <div className={styles.name}>{formatName(candidate)}</div>
          <div className={styles.qualification}>{candidate.mainQualification?.label}</div>
          <div className={styles.address}>{formatCity(candidate)}</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.additionalInfo}>
          <div className={styles.status}>
            {candidate.candidateStatus &&
              {
                [CANDIDATE_STATUS_LABEL['Intérimaire']]: 'intérimaire',
                [CANDIDATE_STATUS_LABEL['CDI Interim']]: 'CDI intérimaire',
                [CANDIDATE_STATUS_LABEL['Candidat évalué']]: 'candidat',
                [CANDIDATE_STATUS_LABEL['Candidat']]: 'candidat',
              }[candidate.candidateStatus]}
          </div>
          {contractEndDate && (
            <>
              <div className={styles.contract}>
                <License color="blue" />
                en contrat
                <br />
                (fin: {contractEndDate.format('DD/MM/YYYY')})
              </div>
            </>
          )}
        </div>
        {candidate.scoring && (
          <>
            <hr />
            <div className={styles.scoring}>
              pertinence du profil
              <ScoringIndicator scoring={candidate.scoring} />
            </div>
          </>
        )}
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <Timing color="blue" />
            <div>{candidate.nbHours} hrs</div>
          </div>
          <div className={styles.stat}>
            <People color="blue" />
            <div>{candidate.nbCompany} clients</div>
          </div>
          <div className={styles.stat}>
            <Edit color="blue" />
            <div>{candidate.nbMissions} contrats</div>
          </div>
        </div>
        <CandidateActions
          trigger={
            <Button variant="secondary" size="medium" className={styles.actions}>
              <OtherHorizontal variant="fill" />
              actions
            </Button>
          }
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          candidate={candidate}
          isSelecting={isSelecting}
          attachCandidate={attachCandidate}
        />
      </div>
    </CardClickable>
  );
};
