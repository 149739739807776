import { ReactComponent as CacesChantierSvg } from './CacesChantier.svg';
import { ReactComponent as CacesChariotSvg } from './CacesChariot.svg';
import { ReactComponent as CacesEchafaudageSvg } from './CacesEchafaudage.svg';
import { ReactComponent as CacesGrueSvg } from './CacesGrue.svg';
import { ReactComponent as CacesNacelleSvg } from './CacesNacelle.svg';
import { ReactComponent as CacesPontsRoulantsSvg } from './CacesPontsRoulants.svg';
import { ReactComponent as CameraIconSvg } from './CameraIcon.svg';
import { ReactComponent as ConflictsIconSvg } from './ConflictsIcon.svg';
import { ReactComponent as DefaultIconSvg } from './DefaultIcon.svg';
import { ReactComponent as DragNDropDisabledIconSvg } from './DragNDropDisabledIcon.svg';
import { ReactComponent as DragNDropIconSvg } from './DragNDropIcon.svg';
import { ReactComponent as EmptySearchSvg } from './EmptySearch.svg';
import { ReactComponent as ExemptionIllusSvg } from './ExemptionIllus.svg';
import { ReactComponent as HeaderMetierSvg } from './HeaderMetier.svg';
import { ReactComponent as IllusCalendarSvg } from './IllusCalendar.svg';
import { ReactComponent as IllusJobSvg } from './IllusJob.svg';
import { ReactComponent as IllusWarningSvg } from './IllusWarning.svg';
import { ReactComponent as LicenceASvg } from './LicenseA.svg';
import { ReactComponent as LicenceA1Svg } from './LicenseA1.svg';
import { ReactComponent as LicenceAMSvg } from './LicenseAM.svg';
import { ReactComponent as LicenceBSvg } from './LicenseB.svg';
import { ReactComponent as LicenceBESvg } from './LicenseBE.svg';
import { ReactComponent as LicenceCSvg } from './LicenseC.svg';
import { ReactComponent as LicenceC1Svg } from './LicenseC1.svg';
import { ReactComponent as LicenceC1ESvg } from './LicenseC1E.svg';
import { ReactComponent as LicenceCESvg } from './LicenseCE.svg';
import { ReactComponent as LicenceDSvg } from './LicenseD.svg';
import { ReactComponent as LicenceD1Svg } from './LicenseD1.svg';
import { ReactComponent as LicenceD1ESvg } from './LicenseD1E.svg';
import { ReactComponent as LicenceDESvg } from './LicenseDE.svg';
import { default as logoAsset } from './logo.svg';
import { ReactComponent as NoInterviewsEyeSvg } from './NoInterviewsEye.svg';
import { default as redCloudAsset } from './redCloud.png';
import { ReactComponent as SuccessSvg } from './Success.svg';
import { ReactComponent as ToastSvg } from './Toast.svg';
import { ReactComponent as WhiteCircleBottomArrowSvg } from './WhiteCircleBottomArrow.svg';

export * from './Sectors';

/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const redCloud = redCloudAsset;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const logo = logoAsset;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CacesChantier = CacesChantierSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CacesChariot = CacesChariotSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CacesEchafaudage = CacesEchafaudageSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CacesGrue = CacesGrueSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CacesNacelle = CacesNacelleSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CacesPontsRoulants = CacesPontsRoulantsSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const DefaultIcon = DefaultIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const EmptySearch = EmptySearchSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ExemptionIllus = ExemptionIllusSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const IllusJob = IllusJobSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const IllusWarning = IllusWarningSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceA = LicenceASvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceA1 = LicenceA1Svg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceAM = LicenceAMSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceB = LicenceBSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceBE = LicenceBESvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceC = LicenceCSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceC1 = LicenceC1Svg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceC1E = LicenceC1ESvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceCE = LicenceCESvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceD = LicenceDSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceD1 = LicenceD1Svg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceD1E = LicenceD1ESvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const LicenceDE = LicenceDESvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const WhiteCircleBottomArrow = WhiteCircleBottomArrowSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const IllusCalendar = IllusCalendarSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const NoInterviewsEye = NoInterviewsEyeSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const CameraIcon = CameraIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const DragNDropIcon = DragNDropIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const DragNDropDisabledIcon = DragNDropDisabledIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const HeaderMetier = HeaderMetierSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const ConflictsIcon = ConflictsIconSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Toast = ToastSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Success = SuccessSvg;
