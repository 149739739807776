import * as z from 'zod';

import { useFetchSMICAmount } from 'src/Hooks/Repositories/useFetchSMICAmount';
import { YesNoUnknown } from 'src/Services/API';

export function useCandidateAssessmentSchema() {
  const { data } = useFetchSMICAmount();
  const smic = data?.smic.monthlyAmount ?? 0;
  return z.object({
    assessment: z.object({
      responses: z.array(
        z.object({
          id: z.number(),
          label: z.string(),
          value: z.number().optional(),
          key: z.string().optional(),
        })
      ),
    }),
    recruiterComment: z.string().optional(),
    supervisorComment: z.string().optional(),
    keyPoints: z.string().optional(),
    supervisorAnswer: z.nativeEnum(YesNoUnknown).optional(),
    choiceDate: z.date().optional(),
    plannedGMMRAmount: z.string().refine(item => {
      return (
        item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= smic)
      );
    }, `Le montant doit être un nombre supérieur au smic (${smic}euros) `),
  });
}

const candidateAssessmentSchemaForType = z.object({
  assessment: z.object({
    responses: z.array(
      z.object({ id: z.number(), label: z.string(), value: z.number().optional() })
    ),
  }),
  recruiterComment: z.string().optional(),
  supervisorComment: z.string().optional(),
  keyPoints: z.string().optional(),
  supervisorAnswer: z.nativeEnum(YesNoUnknown).optional(),
  choiceDate: z.date().optional(),
  plannedGMMRAmount: z
    .string()
    .refine(item => {
      return item.length === 0 || (!isNaN(parseFloat(item ?? '')) && parseFloat(item ?? '') >= 0);
    }, 'Le montant doit être un nombre positif')
    .optional(),
});

export type CandidateAssessmentSchemaType = z.infer<typeof candidateAssessmentSchemaForType>;
