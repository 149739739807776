import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { CandidateService, Criteria } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useSetCriteriaFromMission = (
  extraOptions?: UseMutationOptions<Criteria, unknown, string>
) => {
  const brandCode = useSelector(getBrandCode);
  const dispatch = useDispatch();

  return useMutation(
    [MutationKeys.setCriteriaFromMission],
    async (missionId: string) => {
      const criteria = await CandidateService.matchingControllerGetCriteriaFromMission({
        missionId,
        brandCode,
      });
      dispatch(searchActions.setCriterion(criteria));
      return criteria;
    },
    { ...extraOptions }
  );
};
