import { MutationOptions, useMutation } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getBrandCode, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useOpenPersonalResume = (
  extraOptions?: MutationOptions<void, unknown, { candidateId: string; candidateName: string }>
) => {
  const brandCode = useSelector(getBrandCode);
  const perimeter = useSelector(getPerimeter);
  return useMutation(
    [MutationKeys.openPersonalResume],
    async ({ candidateId, candidateName }) => {
      const base64 = await CandidatesService.candidatesControllerGetCvPerso({
        id: candidateId,
        brandCode,
      });
      ReactGA.event('Consultation de CV personnel', {
        brandCode,
        agency: perimeter?.defaultAgencyId,
        regionId: perimeter?.regionId,
        zoneId: perimeter?.zoneId,
      });
      const win = window.open();
      win?.document.write(
        `<iframe src="data:application/pdf;base64,${base64}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
      );
      if (win) {
        win.document.title = `CV ${candidateName}`;
      }
    },
    {
      ...extraOptions,
    }
  );
};
