import { Recherche } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  CardCheckable,
  CardContent,
  Checkbox,
  ToggleMenu,
  ToggleMenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Fragment, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { z } from 'zod';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import {
  EXPERIENCE_TYPE,
  SORT_OPTIONS,
  SORT_ORDER,
} from 'src/Containers/CandidateFile/History/Background/Background.types';
import {
  getSubtitles,
  groupExperiences,
  totalDurationInHours,
} from 'src/Containers/CandidateFile/History/Background/utils';
import { useFetchCandidateExperiencesByCompany } from 'src/Hooks/Candidates/useFetchCandidateExperiencesByCompany';
import { useFetchCandidateSkills } from 'src/Hooks/Candidates/useFetchCandidateSkills';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { formSchema } from '../CandidateResume.schema';

import styles from './Experiences.module.scss';
import { ExperiencesProps } from './Experiences.types';

export const Experiences = ({ candidateId, control, watch }: ExperiencesProps) => {
  const skillsQuery = useFetchCandidateSkills(candidateId, { retry: false });
  const experiencesQuery = useFetchCandidateExperiencesByCompany(candidateId, true, {
    retry: false,
  });
  const isError = skillsQuery.isError || experiencesQuery.isError;

  const selectedQualificationIds = watch('qualifications');

  const selectedQualifications = selectedQualificationIds
    .map(qualificationId =>
      skillsQuery.data?.qualifications?.find(qualification => qualification.id === qualificationId)
    )
    .filter(Boolean);

  const [experiencesForAllQualifications, setExperiencesForAllQualifications] =
    useState<boolean>(true);

  const { field: experiencesField } = useController({
    name: 'experiences',
    control,
  });

  const [experienceType, setExperienceType] = useState<EXPERIENCE_TYPE>(
    EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
  );

  const allExperiences = useMemo(() => {
    const experiences = experiencesForAllQualifications
      ? experiencesQuery.data
      : experiencesQuery.data && {
          ...experiencesQuery.data,
          experiences: experiencesQuery.data.experiences.filter(
            el =>
              el.experiencesByCompany.externalExperiences.some(extExp =>
                selectedQualificationIds?.includes(extExp.qualification?.id || '')
              ) ||
              el.experiencesByCompany.internalExperiences.some(intExp =>
                selectedQualificationIds?.includes(intExp.qualification?.id || '')
              )
          ),
        };
    return {
      internal:
        experiences &&
        groupExperiences(experiences, true, SORT_OPTIONS.YEAR, SORT_ORDER.DESCENDING),
      external:
        experiences &&
        groupExperiences(experiences, false, SORT_OPTIONS.YEAR, SORT_ORDER.DESCENDING),
    };
  }, [experiencesQuery.data, experiencesForAllQualifications, selectedQualificationIds]);

  const experiencesToDisplay = useMemo(() => {
    return experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
      ? allExperiences.internal
      : allExperiences.external;
  }, [allExperiences.external, allExperiences.internal, experienceType]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {`sélectionnez les expériences à mettre en avant sur ${
          experiencesForAllQualifications
            ? `l'ensemble des qualifications du talent`
            : selectedQualifications.length > 1
            ? 'les qualifications :'
            : 'la qualification :'
        }
      `}
        {!experiencesForAllQualifications && (
          <div className={styles.qualifications}>
            {selectedQualifications
              .map(qualification =>
                qualification.label
                  ? qualification.label.charAt(0) + qualification.label.slice(1).toLowerCase()
                  : ''
              )
              .filter(Boolean)
              .join(', ')}
          </div>
        )}
      </div>
      <ToggleMenu value={experienceType} onChange={setExperienceType}>
        <ToggleMenuItem value={EXPERIENCE_TYPE.INTERNAL_EXPERIENCES}>chez randstad</ToggleMenuItem>
        <ToggleMenuItem
          value={EXPERIENCE_TYPE.EXTERNAL_EXPERIENCES}
          disabled={allExperiences.external?.labels.length === 0}
        >
          en externe
        </ToggleMenuItem>
      </ToggleMenu>
      <div className={styles.optionsContainer}>
        {selectedQualifications.length !== skillsQuery.data?.qualifications?.length && (
          <Checkbox
            checked={!experiencesForAllQualifications}
            onChange={() => setExperiencesForAllQualifications(!experiencesForAllQualifications)}
            label="visualiser uniquement les expériences sur les qualifications sélectionnées en amont"
          />
        )}
      </div>
      <div className={styles.experiencesContainer}>
        {isError ? (
          <>
            <ErrorMessage message="Une erreur est survenue dans la récupération des données" />
            <div>
              <Button.Medium
                type="button"
                onClick={() => {
                  if (!experiencesQuery.data) experiencesQuery.refetch();
                  if (!skillsQuery.data) skillsQuery.refetch();
                }}
              >
                réessayer
              </Button.Medium>
            </div>
          </>
        ) : !experiencesToDisplay ? (
          <>
            <ContentLoader height="2.5rem" width="100%">
              <rect x="2%" y="10" rx="4" ry="4" width="100%" height="200" />
            </ContentLoader>
            {new Array(3).fill(null).map((_, idx) => (
              <ContentLoader key={idx} height="5.75rem" width="100%">
                <rect x="2%" y="10" rx="4" ry="4" width="100%" height="200" />
              </ContentLoader>
            ))}
          </>
        ) : experiencesToDisplay.labels.length === 0 ? (
          <div className={styles.noInfo}>
            <Recherche />
            <span>
              désolé,
              <br />
              aucune expérience{' '}
              {!experiencesForAllQualifications && 'sur les qualifications sélectionnées en amont'}
            </span>
          </div>
        ) : (
          experiencesToDisplay.labels?.map(label => (
            <Fragment key={label}>
              {experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES && (
                <div className={styles.groupTitle} key={label}>
                  {label}
                </div>
              )}
              {experiencesToDisplay?.groupedExperiences
                .filter(experience => experience.label === label)
                .map(experience => (
                  <CardCheckable
                    key={`${experience.companyName} - ${experience.label}`}
                    color="beige"
                    checked={experiencesField.value.some(
                      exp =>
                        exp.label === experience.label && exp.companyName === experience.companyName
                    )}
                    onChange={checked => {
                      if (checked) {
                        if (
                          !experiencesField.value.some(
                            el =>
                              el.label === experience.label &&
                              el.companyName === experience.companyName
                          )
                        ) {
                          const uniqueLabels: string[] = [];
                          const labelSet = new Set();
                          experience.experiences.forEach(q => {
                            if (
                              q.qualification &&
                              q.qualification.label &&
                              !labelSet.has(q.qualification.label.toLowerCase())
                            ) {
                              labelSet.add(q.qualification.label.toLowerCase());
                              uniqueLabels.push(q.qualification.label.toLowerCase());
                            }
                          });
                          const totalDurationInMonths = Math.floor(
                            totalDurationInHours(experience.experiences) / (24 * 30)
                          );
                          const totalDurationInWeeks = Math.floor(
                            totalDurationInHours(experience.experiences) / (24 * 7)
                          );
                          const totalDurationInDays = Math.floor(
                            totalDurationInHours(experience.experiences) / 24
                          );
                          const item: z.infer<typeof formSchema>['experiences'][number] = {
                            qualifications: uniqueLabels,
                            label: experience.label,
                            companyName: experience.companyName,
                            firstDate:
                              experience.experiences.length > 0
                                ? moment(experience.experiences[0].startDate).format('L')
                                : '',
                            lastDate:
                              experience.experiences.length > 0
                                ? moment(
                                    experience.experiences[experience.experiences.length - 1]
                                      .endDate
                                  ).format('L')
                                : '',
                            nbContracts: pluralFormat(experience.experiences.length, 'contrat'),
                            duration: `${
                              totalDurationInMonths > 0
                                ? `${totalDurationInMonths} mois`
                                : totalDurationInWeeks > 0
                                ? `${pluralFormat(totalDurationInWeeks, 'semaine')}`
                                : totalDurationInDays > 0
                                ? `${pluralFormat(totalDurationInDays, 'jour')} `
                                : `${pluralFormat(
                                    Math.floor(totalDurationInHours(experience.experiences)),
                                    'heure'
                                  )}`
                            }`,
                            isInternalExperience: experience.isInternalExperiences,
                          };
                          experiencesField.onChange([...experiencesField.value, item]);
                        }
                      } else {
                        experiencesField.onChange(
                          experiencesField.value.filter(
                            el =>
                              !(
                                el.label === experience.label &&
                                el.companyName === experience.companyName
                              )
                          )
                        );
                      }
                    }}
                  >
                    <CardContent
                      title={experience.companyName}
                      supportingLines={getSubtitles(experience.experiences)}
                    />
                  </CardCheckable>
                ))}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
};
