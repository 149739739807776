import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { TextAreaWithLightTitle } from '@randstad-lean-mobile-factory/react-form-fields';
import { TextAreaWithLightTitleProps } from '@randstad-lean-mobile-factory/react-form-fields/dist/Components/TextAreaWithLightTitle/TextAreaWithLightTitle.types';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import styles from './TextAreaWithLightTitleWithDisplayMode.module.scss';
import { OwnProps } from './TextAreaWithLightTitleWithDisplayMode.types';

function TextAreaWithLightTitleWithDisplayMode<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({ isDisplayMode, ...props }: OwnProps<TFieldValues, TName>) {
  const controller = useController({ name: props.name, control: props.control });
  return (
    <>
      {isDisplayMode ? (
        <WithLightTitle title={props.title}>
          <div className={styles.text}>
            {controller.field.value ?? "champ non rempli lors de l'entretien"}
          </div>
        </WithLightTitle>
      ) : (
        <TextAreaWithLightTitle {...(props as TextAreaWithLightTitleProps<TFieldValues, TName>)} />
      )}
    </>
  );
}

export default TextAreaWithLightTitleWithDisplayMode;
