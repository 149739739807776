import { SegmentedMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Find,
  RecrutLiveIcon,
  Sms,
  User,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Title from 'src/Components/Title';
import { ParametersPopup } from 'src/Containers/MainPage/TopBar/ParametersPopup';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';

import Apps from './Apps/Apps.component';
import styles from './TopBar.module.scss';
import { Props, TopBarNavigation } from './TopBar.types';

enum TOP_BAR_URL {
  INTERVIEWS = '/interviews',
  ADD = '/new-talent',
}

const getActiveSection = (url: string) => {
  if (url.includes(TOP_BAR_URL.INTERVIEWS)) {
    return 'interviews';
  } else if (url.includes(TOP_BAR_URL.ADD)) {
    return 'newTalent';
  } else {
    return 'homePage';
  }
};

export const getTopBarNavigation = (tab: string, isProd: boolean): TopBarNavigation => {
  const navigation: TopBarNavigation = {
    homePage: {
      name: 'rechercher',
      path: '',
      icon: (
        <Find className={classnames(styles.icon, { [styles.selectedIcon]: tab === 'homePage' })} />
      ),
    },
    interviews: {
      name: 'entretiens',
      path: '/interviews/statistics',
      icon: (
        <Sms className={classnames(styles.icon, { [styles.selectedIcon]: tab === 'interviews' })} />
      ),
    },
  };

  if (!isProd) {
    navigation.newTalent = {
      name: 'nouveau talent',
      path: '/new-talent',
      icon: (
        <User className={classnames(styles.icon, { [styles.selectedIcon]: tab === 'newTalent' })} />
      ),
    };
  }

  return navigation;
};

const TopBar = ({ history }: Props) => {
  const { isProd } = useIsProductionEnvironment();
  const location = useLocation();
  const activeSection = getActiveSection(location.pathname);
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <RecrutLiveIcon />
        <div className={styles.separator} />
        <Title H="h1" title="recrut'live" className={styles.title} />
      </div>
      <div className={styles.subContainer} data-theme="dark">
        <SegmentedMenu
          selected={activeSection}
          controlItemClassName={styles.segmentedMenuText}
          bottomBarClassName={styles.bottomBar}
          controls={Object.entries(getTopBarNavigation(activeSection, isProd))}
          keyValueExtractor={([section, { name, icon }]) => ({
            key: section,
            value: name,
            leftIcon: icon,
          })}
          onSelectionChange={([_, { path }]) => {
            history.push(path);
          }}
        />
        <Apps />
        <ParametersPopup />
      </div>
    </div>
  );
};
export default TopBar;
