import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { formSchema } from 'src/Containers/CandidateResume/CandidateResume.schema';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ResumeGeneratorService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const usePreviewCandidateResume = (
  candidateId: string,
  formState: z.infer<typeof formSchema>
) => {
  const brandCode = useSelector(getBrandCode);

  return useQuery(
    [QueryKeys.previewCandidateResume, candidateId, formState],
    async () => {
      const isCvAnonymous =
        !formState.employee.address &&
        !formState.employee.contactInfo &&
        !formState.employee.identity;

      const osmoseDocumentName = [...formState.qualifications, isCvAnonymous ? 'A' : undefined]
        .filter(Boolean)
        .join('_');

      const candidateResume: Buffer =
        await ResumeGeneratorService.resumeGeneratorControllerGetCandidateResume(
          {
            candidateId,
            body: {
              qualifications: formState.qualifications,
              businessesSkills: formState.skills.businessSkills,
              behaviourSkills: formState.skills.behaviourSkills,
              experiences: formState.experiences,
              diplomas: formState.degrees.diplomas,
              tests: formState.degrees.tests,
              habilitations: formState.degrees.habilitations,
              drivingLicences: formState.degrees.drivingLicences,
              employeeData: formState.employee,
              description: formState.description,
              brandCode,
              osmoseTitle: osmoseDocumentName,
              osmoseUpload: false,
            },
          },
          { responseType: 'arraybuffer' }
        );

      const blob = new Blob([candidateResume], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      return url;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};
