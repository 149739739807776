import { z } from 'zod';

import { AxiosInterceptors } from './types';

const errorSchema = z.object({
  exceptionType: z.string().optional(),
  context: z.string(),
  route: z.string().optional(),
  response: z.any(),
});

export const defaultInterceptors: AxiosInterceptors = {
  errorInterceptor: async error => {
    const { response, request, message, config } = error;
    if (response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const parsedResponse = errorSchema.safeParse(response.data);
      console.group('Erreur');
      if (config) {
        if (parsedResponse.success) {
          console.log(
            'Requête :',
            `${parsedResponse.data.context} (${config.method?.toUpperCase()} ${config.url})`
          );
        } else {
          console.log('Requête :', `${config.method?.toUpperCase()} ${config.url}`);
        }
        if (config.data && config.data !== 'null')
          try {
            console.log('Payload :', JSON.parse(config.data));
            // eslint-disable-next-line no-restricted-syntax
          } catch {
            console.log('Payload :', config.data);
          }
      }
      console.log('Statut :', response.status);
      if (parsedResponse.success) {
        if (parsedResponse.data.route) {
          if (parsedResponse.data.exceptionType === 'BusinessApiException') {
            console.log('Route BAPI :', parsedResponse.data.route);
          } else if (parsedResponse.data.exceptionType === 'MbapiApiException') {
            console.log('Route MBAPI :', parsedResponse.data.route);
          } else {
            console.log('Contexte :', parsedResponse.data.route);
          }
        }
        console.log("Message d'erreur :", parsedResponse.data.response);
      } else console.log('Réponse :', response.data);
      console.groupEnd();
    } else if (request) {
      // The request was made but no response was received
      // `request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.group('Erreur');
      if (config) {
        console.log('Requête :', `${config.method?.toUpperCase()} ${config.url}`);
        if (config.data && config.data !== 'null')
          try {
            console.log('Payload :', JSON.parse(config.data));
            // eslint-disable-next-line no-restricted-syntax
          } catch {
            console.log('Payload :', config.data);
          }
      }
      console.log('Réponse :', message);
      console.groupEnd();
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', message);
    }

    // Either throw or return a rejected promise, will resolve in then otherwise
    return Promise.reject(error);
  },
};
