import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { QueryKeys } from 'src/Hooks/types';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService, QualificationEntity } from 'src/Services/API';

export const useFetchCandidateSuggestedQualifications = (
  candidateId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraOptions?: UseQueryOptions<QualificationEntity[], AxiosError, QualificationEntity[], any>
) => {
  const brandCode = useSelector(getBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateSuggestedQualifications, candidateId],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateSuggestedQualifications({
        candidateId,
        brandCode,
      });
    },
    { ...extraOptions }
  );
};
