import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { useMemo } from 'react';

import { MatchingCriteriaItemWithLabel, RepositoriesService, WishPriority } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchQualifications = (
  extraOptions?: UseMutationOptions<MatchingCriteriaItemWithLabel[], unknown, string>
) => {
  const societyCode = '001';

  return useMutation(
    [MutationKeys.fetchQualifications],
    async (keyword: string) => {
      const qualifications = await RepositoriesService.repositoriesControllerGetQualifications({
        keyword: keyword,
        societyCode,
      });
      const results = qualifications.map(qualification => {
        return {
          identifier: qualification.id,
          label: qualification.label,
          wishPriority: WishPriority.Obligatoire,
        };
      });
      return results;
    },
    { ...extraOptions }
  );
};

export const useFetchQualificationsWithThrottle = () => {
  const qualificationsMutation = useFetchQualifications();

  return useMemo(
    () => ({
      ...qualificationsMutation,
      mutate: debounce(qualificationsMutation.mutate, 500),
    }),
    [qualificationsMutation]
  );
};
