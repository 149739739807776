import { TextInput } from '@randstad-lean-mobile-factory/react-form-fields';
import { TextInputProps } from '@randstad-lean-mobile-factory/react-form-fields/dist/Components/TextInput/TextInput.types';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { OwnProps } from './TextInputWithDisplayMode.types';

function TextInputWithDisplayMode<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({ isDisplayMode, ...props }: OwnProps<TFieldValues, TName>) {
  const controller = useController({ name: props.name, control: props.control });
  return (
    <>
      {isDisplayMode ? (
        <>{controller.field.value || "champ non rempli lors de l'entretien"}</>
      ) : (
        <TextInput {...(props as TextInputProps<TFieldValues, TName>)} />
      )}
    </>
  );
}

export default TextInputWithDisplayMode;
