export function formatCity(address: undefined): undefined;
export function formatCity(address: {
  city: string;
  postalCode?: string;
  zipCode?: string;
}): string;
export function formatCity(address: {
  city?: string;
  postalCode: string;
  zipCode?: string;
}): string;
export function formatCity(address: {
  city?: string;
  postalCode?: string;
  zipCode: string;
}): string;
export function formatCity(address: {
  city?: string;
  postalCode?: string;
  zipCode?: string;
}): string | undefined;
export function formatCity(
  address:
    | {
        city?: string;
        postalCode?: string;
        zipCode?: string;
      }
    | undefined
): string | undefined;
export function formatCity(
  address:
    | {
        city?: string;
        postalCode?: string;
        zipCode?: string;
      }
    | undefined
): string | undefined {
  if (!address) return;
  if (!address.city && !address.postalCode && !address.zipCode) return;
  const postalCode = address.postalCode || address.zipCode;
  if (address.city && postalCode) return `${address.city} (${postalCode})`;
  return [address.city, postalCode].filter(Boolean).join(' ');
}
