import { CircleAdd, Inversion } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, Link, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  BackTo,
  IllusRLCACESSmall,
  IllusRLCalendarSmall,
  IllusRLCompanySmall,
  IllusRLDiplomaSmall,
  IllusRLHabilitationsSmall,
  IllusRLKeywordSmall,
  IllusRLLanguageSmall,
  IllusRLLicenseSmall,
  IllusRLPotentialSmall,
  IllusRLQualificationSmall,
  IllusRLServiceSmall,
  IllusRLSkillsSmall,
  IllusRLVillesSmall,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useIsMutating } from '@tanstack/react-query';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { EmptySearch } from 'src/Assets';
import CriteriaCard from 'src/Components/CriteriaCard';
import { useActiveCriteria } from 'src/Hooks/Criteria';
import { getSelectedMissionId } from 'src/Redux/Missions/Selectors';
import { getSearchCriteria } from 'src/Redux/Search/Selectors';
import { SEARCH_MODE } from 'src/Redux/Search/Types';

import { MissionSearchModal } from '../Modals/MissionSearchModal';

import styles from './SearchSummary.module.scss';
import { Props } from './SearchSummary.types';

const IconMapping = {
  caces: <IllusRLCACESSmall className={styles.icon} />,
  qualifications: <IllusRLQualificationSmall className={styles.icon} />,
  compétences: <IllusRLSkillsSmall className={styles.icon} />,
  diplômes: <IllusRLDiplomaSmall className={styles.icon} />,
  comptes: <IllusRLCompanySmall className={styles.icon} />,
  services: <IllusRLServiceSmall className={styles.icon} />,
  'pré-affectations': <IllusRLPotentialSmall className={styles.icon} />,
  disponibilités: <IllusRLCalendarSmall className={styles.icon} />,
  villes: <IllusRLVillesSmall className={styles.icon} />,
  permis: <IllusRLLicenseSmall className={styles.icon} />,
  langues: <IllusRLLanguageSmall className={styles.icon} />,
  habilitations: <IllusRLHabilitationsSmall className={styles.icon} />,
  'mots clés': <IllusRLKeywordSmall className={styles.icon} />,
};

export const SearchSummary = (props: Props) => {
  const criteriaTable = useActiveCriteria();
  const criteria = useSelector(getSearchCriteria);
  const selectedMissionId = useSelector(getSelectedMissionId);

  const resetFilters = () => {
    props.resetWorkingHoursFilter();
    props.resetCandidateStatuses();
  };

  const loading = useIsMutating(['searchCriteriasFromEDP']);
  const location = useLocation();
  const history = useHistory();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current && !loading) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('edpId')) {
        queryParams.delete('edpId');
        history.replace({
          search: queryParams.toString(),
        });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);

  const [isMissionSearchModalOpen, setIsMissionSearchModalOpen] = useState(false);

  return (
    <div className={classnames(styles.container, props.className)}>
      <div className={styles.title}>critères de la recherche</div>
      {selectedMissionId ? (
        <div className={styles.missionSelection}>
          pour la commande {selectedMissionId}
          <Link onClick={() => setIsMissionSearchModalOpen(true)}>
            <Inversion /> modifier
          </Link>
        </div>
      ) : (
        <div className={styles.missionSelection}>
          0 commande associée
          <Link onClick={() => setIsMissionSearchModalOpen(true)}>
            <CircleAdd /> ajouter une commande
          </Link>
        </div>
      )}
      <div className={styles.criteria}>
        {props.searchMode === SEARCH_MODE.BY_NAME && (
          <>
            <div className={styles.text}>
              vous êtes en train d'effectuer une recherche par nom / prénom
            </div>
            <div className={styles.emptyIcon}>
              <EmptySearch />
            </div>
          </>
        )}
        {props.searchMode === SEARCH_MODE.BY_CRITERIA && !props.canSearch && (
          <>
            <div className={styles.text}>
              veuillez sélectionner un ou plusieurs critères pour faire une recherche
            </div>
            <div className={styles.emptyIcon}>
              <EmptySearch />
            </div>
          </>
        )}
        {props.searchMode === SEARCH_MODE.BY_CRITERIA && props.canSearch && (
          <>
            <div className={styles.iconsTopButtons}>
              <div
                className={styles.topButton}
                onClick={() => {
                  props.reset();
                }}
              >
                <BackTo />
                <p className={styles.topButtonText}>réinitialiser</p>
              </div>
            </div>
            {criteriaTable.map(
              criteria =>
                criteria.items.length > 0 && (
                  <WithLightTitle
                    key={criteria.label}
                    title={criteria.label}
                    className={styles.criteriaList}
                  >
                    <>
                      {criteria.items.map(element => {
                        return (
                          <CriteriaCard
                            key={element.identifier}
                            notMandatory={criteria.notMandatory}
                            className={
                              criteria.items.length > 1 ? styles.multiCriteriaCard : undefined
                            }
                            icon={IconMapping[criteria.label as keyof typeof IconMapping]}
                            onWeightChange={
                              criteria.onWeightUpdate
                                ? weight => {
                                    criteria.onWeightUpdate?.(element.identifier, weight);
                                  }
                                : undefined
                            }
                            onRadiusChange={
                              criteria.onRadiusUpdate
                                ? radius => {
                                    criteria.onRadiusUpdate?.(radius);
                                  }
                                : undefined
                            }
                            weight={element.weight}
                            radius={element.radius}
                            title={element.label ?? ''}
                            onDelete={() => criteria.onDelete(element.identifier)}
                          />
                        );
                      })}
                    </>
                  </WithLightTitle>
                )
            )}
          </>
        )}
      </div>
      <Button
        disabled={!props.canSearch}
        className={styles.searchButton}
        onClick={() => {
          resetFilters();
          props.onSearch();
        }}
      >
        lancer ma recherche
      </Button>
      <MissionSearchModal
        open={isMissionSearchModalOpen}
        onClose={() => setIsMissionSearchModalOpen(false)}
      />
    </div>
  );
};
