import {
  FetchButton,
  Message,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { EuroSign, Loading } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useController, useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as z from 'zod';

import HourCard from 'src/Components/HourCard';
import ExclusiveSelectionGroupWithDisplayMode from 'src/Components/WithDisplayMode/ExclusiveSelectionGroupWithDisplayMode';
import NumberInputDeprecatedWithDisplayMode from 'src/Components/WithDisplayMode/NumberInputDeprecatedWithDisplayMode';
import TextAreaWithLightTitleWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithLightTitleWithDisplayMode';
import ToggleSwitchWithDisplayMode from 'src/Components/WithDisplayMode/ToggleSwitchWithDisplayMode';
import InterviewValidationModal from 'src/Containers/Modals/InterviewsModals';
import InterviewRefusalModal from 'src/Containers/Modals/InterviewsModals/InterviewRefusalModal';
import { useCloseCandidateR2 } from 'src/Hooks/CandidateR2/useCloseCandidateR2';
import { useFetchCandidateETests } from 'src/Hooks/CandidateR2/useFetchCandidateETests';
import { useFetchCandidateR2FromURL } from 'src/Hooks/CandidateR2/useFetchCandidateR2FromURL';
import { useUpdateCandidateR2 } from 'src/Hooks/CandidateR2/useUpdateCandidateR2';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { R1Hours, R1Priority, YesNoChoicesEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import MaxMobilityFields from '../../../../Components/MaxMobilityFields';
import JobsSelection from '../../JobsSelection/JobsSelection.component';
import { ButtonType } from '../../R1/CandidateAssessment/CandidateAssessment.component';

import styles from './R2Validation.module.scss';
import { useCandidateValidationSchema } from './R2Validation.types';

const defaultHoursPriorities = Object.values(R1Hours).map(hours => ({
  hours,
  priority: hours === 'travail en journée' ? R1Priority.Favorable : R1Priority.Neutre,
}));

const R2Validation = () => {
  const { openCandidateFile } = useOpenCandidateFile();
  const units = useSelector(getPerimeterUnits);
  const params = useParams<{
    R2Id: string;
  }>();
  const history = useHistory();
  const candidate = useFetchCandidateDetailsFromURL();
  const {
    data: candidateR2Data,
    isLoading: isLoadingCandidateR2,
    isSuccess: isCandidateR2Success,
    isFetching: isFetchingCandidateR2,
    isError: isErrorCandidateR2,
  } = useFetchCandidateR2FromURL();

  const candidateR2FetchStatus = toFetchStatus({
    isError: isErrorCandidateR2,
    isLoading: isLoadingCandidateR2,
    isSuccess: isCandidateR2Success,
    isFetching: isFetchingCandidateR2,
  });

  const zodSchema = useCandidateValidationSchema();

  const { control, watch, setValue, handleSubmit, reset, formState } = useFormWithZodResolver({
    schema: zodSchema,
    defaultValues: {
      validation: candidateR2Data?.validation?.validation,
      comment: candidateR2Data?.validation?.comment,
      hoursPriorities:
        // if R2 was created before new hours format then we take new format
        candidateR2Data?.hoursPriorities.length !== undefined &&
        candidateR2Data?.hoursPriorities.length > 6
          ? candidateR2Data?.hoursPriorities
          : defaultHoursPriorities,
      gmmr:
        candidateR2Data?.validation?.gmmr?.toString() ??
        candidateR2Data?.r1Data.gmmr?.toString() ??
        undefined,
      maxMobility: {
        maxMobilityDistance: candidateR2Data?.maxMobility?.maxMobilityDistance?.toString(),
        maxMobilityTime: candidateR2Data?.maxMobility?.maxMobilityTime?.toString(),
      },
      isCDI: candidateR2Data?.validation?.isCDI,
      jobs: candidateR2Data?.jobs ?? [],
      selectedJobs: candidateR2Data?.jobs?.filter(
        job =>
          candidateR2Data.selectedJobIds?.includes(job.id) ||
          (candidate.data?.qualificationId &&
            job.qualifications.includes(candidate.data?.qualificationId))
      ),
      otherJobs: candidateR2Data?.jobs?.filter(
        job =>
          !candidateR2Data.selectedJobIds?.includes(job.id) &&
          !(
            candidate.data?.qualificationId &&
            job.qualifications.includes(candidate.data?.qualificationId)
          )
      ),
      mainQualificationId: candidateR2Data?.mainQualificationId ?? candidate.data?.qualificationId,
    },
  });

  const candidateR2Update = useUpdateCandidateR2(params.R2Id);
  const candidateR2Close = useCloseCandidateR2(params.R2Id);
  const updateFetchStatus = toFetchStatus(candidateR2Update);
  const finishStatus = toFetchStatus(candidateR2Close);
  const [buttonType, setButtonType] = useState(ButtonType.VALIDATE);
  const [validationModal, setValidationModal] = useState(false);
  const [refusalModal, setRefusalModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState([] as JSX.Element[]);
  const maxMobilityDistance = watch('maxMobility.maxMobilityDistance');
  const maxMobilityTime = watch('maxMobility.maxMobilityTime');
  const isCDIController = useController({ name: 'isCDI', control });
  const cgcValidationController = useController({ control, name: 'validation' });
  const eTests = useFetchCandidateETests();

  const mandatoryElements = [
    {
      key: 'mobility',
      label: <div>La mobilité du candidat n'est pas conforme</div>,
      value:
        maxMobilityDistance !== undefined &&
        parseFloat(maxMobilityDistance ?? '0') >= 40 &&
        maxMobilityTime !== undefined &&
        parseFloat(maxMobilityTime ?? '0') >= 75,
    },

    {
      key: 'validationToggles',
      label: (
        <div>
          Vous devez valider tous les éléments de l'écran de{' '}
          <p
            className={styles.link}
            onClick={() => {
              if (formStatusContext.hasFormBeenTouched) {
                formStatusContext.openNavWarningModal({ destination: 'projection' });
                formStatusContext.setFetchStatus(FETCH_STATUS.FULFILLED);
              } else {
                history.push('projection');
              }
            }}
          >
            projections
          </p>
        </div>
      ),
      value:
        candidateR2Data?.validationElements !== undefined &&
        !candidateR2Data?.validationElements.responses.find(answer => answer.value === false),
    },
    {
      key: 'amount',
      label: <div>Merci de renseigner le montant GMMR</div>,
      value: !!watch('gmmr'),
    },
    {
      key: 'jobs',
      label: <div>Vous devez retenir 3 métiers parmi ceux du candidat</div>,
      value: !!watch('selectedJobs') && watch('selectedJobs').length >= 3,
    },
    {
      key: 'mainQualification',
      label: <div>Vous devez choisir une qualification principale</div>,
      value: !!watch('mainQualificationId'),
    },
  ];

  const canModifyR2 = useMemo(
    () => candidateR2Data && units.includes(candidateR2Data.cgcId) && !candidateR2Data.isClosed,
    [candidateR2Data, units]
  );

  const validationHandleSubmit = (options?: { finish?: boolean; force?: boolean }) => {
    if (candidateR2Data && canModifyR2) {
      const handleSubmitSuccess = (values: z.infer<typeof zodSchema>) => {
        const updateBody = {
          ...candidateR2Data,
          hoursPriorities: values.hoursPriorities,
          maxMobility: {
            maxMobilityDistance: values.maxMobility?.maxMobilityDistance
              ? parseFloat(values.maxMobility?.maxMobilityDistance)
              : undefined,
            maxMobilityTime: values.maxMobility?.maxMobilityTime
              ? parseFloat(values.maxMobility?.maxMobilityTime)
              : undefined,
          },
          validation: {
            validation: values.validation,
            comment: values.comment,
            gmmr: values.gmmr ? parseFloat(values.gmmr) : undefined,
            isCDI: values.isCDI,
          },
          selectedJobIds: values.selectedJobs.map(job => job.id),
          jobs: values.jobs,
        };
        if (options?.finish) {
          candidateR2Close.mutate({ candidateR2: { ...updateBody, eTests: eTests.data } });
        } else {
          candidateR2Update.mutate(updateBody);
        }
        reset(values, { keepValues: true });
      };
      setErrorMessage([]);
      setButtonType(options?.finish ? ButtonType.FINISH : ButtonType.VALIDATE);
      if (options?.finish) {
        if (isCDIController.field.value) {
          if (!mandatoryElements.every(item => item.value === true)) {
            setErrorMessage(
              mandatoryElements.filter(item => item.value === false).map(element => element.label)
            );
          } else {
            setButtonType(ButtonType.FINISH);
            handleSubmit(values => handleSubmitSuccess(values))();
          }
        } else {
          setRefusalModal(true);
        }
      } else {
        setButtonType(ButtonType.VALIDATE);
        handleSubmit(values => handleSubmitSuccess(values))();
      }
    }
  };

  useEffect(() => {
    if (isCandidateR2Success && candidate.isSuccess) {
      setValue('comment', candidateR2Data?.validation?.comment);
      setValue('validation', candidateR2Data?.validation?.validation);
      setValue(
        'hoursPriorities',
        // if R2 was created before new hours format then we take new format
        candidateR2Data?.hoursPriorities.length !== undefined &&
          candidateR2Data?.hoursPriorities.length > 6
          ? candidateR2Data?.hoursPriorities
          : defaultHoursPriorities
      );
      setValue(
        'gmmr',
        candidateR2Data?.validation?.gmmr?.toString() ??
          candidateR2Data?.r1Data.gmmr?.toString() ??
          undefined
      );
      setValue('maxMobility', {
        maxMobilityDistance: candidateR2Data?.maxMobility?.maxMobilityDistance?.toString(),
        maxMobilityTime: candidateR2Data?.maxMobility?.maxMobilityTime?.toString(),
      });
      setValue('isCDI', candidateR2Data?.validation?.isCDI);
      setValue('jobs', candidateR2Data?.jobs ?? []);
      setValue(
        'selectedJobs',
        candidateR2Data?.jobs?.filter(
          job =>
            candidateR2Data.selectedJobIds?.includes(job.id) ||
            (candidate.data?.qualificationId &&
              job.qualifications.includes(candidate.data?.qualificationId))
        ) ?? []
      );
      setValue(
        'otherJobs',
        candidateR2Data?.jobs?.filter(
          job =>
            !candidateR2Data.selectedJobIds?.includes(job.id) &&
            !(
              candidate.data?.qualificationId &&
              job.qualifications.includes(candidate.data?.qualificationId)
            )
        ) ?? []
      );
      setValue(
        'mainQualificationId',
        candidateR2Data?.mainQualificationId ?? candidate.data.qualificationId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR2Success, setValue, candidate.isSuccess, candidate.isFetching]);

  const hoursPrioritiesController = useFieldArray({
    name: 'hoursPriorities',
    control,
    keyName: 'key',
  });
  const selectedJobsController = useController({ name: 'selectedJobs', control });
  const otherJobsController = useController({ name: 'otherJobs', control });
  const jobsController = useController({ name: 'jobs', control });
  const mainQualificationIdController = useController({ name: 'mainQualificationId', control });

  const jobs = watch('jobs');
  useEffect(() => {
    setValue(
      'selectedJobs',
      jobs?.filter(
        job =>
          selectedJobsController.field.value.map(jobItem => jobItem.id)?.includes(job.id) ||
          job.qualifications.includes(mainQualificationIdController.field.value ?? '')
      ) ?? []
    );
    setValue(
      'otherJobs',
      jobs?.filter(
        job =>
          !selectedJobsController.field.value.map(jobItem => jobItem.id)?.includes(job.id) &&
          !job.qualifications.includes(mainQualificationIdController.field.value ?? '')
      ) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, mainQualificationIdController.field.value]);

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: () => validationHandleSubmit as () => Promise<void>,
      fetchStatus: updateFetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, updateFetchStatus]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (errorMessage.length > 0 && messagesEndRef.current) scrollToBottom();
  }, [errorMessage]);
  return (
    <div className={styles.form}>
      {candidateR2FetchStatus === FETCH_STATUS.FULFILLED ? (
        <>
          <div className={styles.title}>choix du recruteur CGC</div>
          <WithLightTitle title="sur la validation du R2" className={styles.withMarginBottom}>
            <ExclusiveSelectionGroupWithDisplayMode
              values={Object.values(YesNoChoicesEnum)}
              selected={cgcValidationController.field.value}
              setSelected={value => cgcValidationController.field.onChange(value)}
              canBeReset
              getStringValue={value => value}
              getKey={value => value}
              isDisplayMode={candidateR2Data?.isClosed}
              large
            />
          </WithLightTitle>
          <TextAreaWithLightTitleWithDisplayMode
            name="comment"
            title="commentaires sur votre choix"
            className={classNames(styles.withMarginBottom, styles.commentText)}
            control={control}
            isDisplayMode={candidateR2Data?.isClosed}
          />

          <div className={styles.separator} />

          <div className={styles.title}>éléments contractuels pour le contrat</div>

          <div className={styles.toggleContainer}>
            <ToggleSwitchWithDisplayMode
              onCheckStatusChange={isCDIController.field.onChange}
              checked={isCDIController.field.value}
              isDisplayMode={candidateR2Data?.isClosed}
              toggleLabel="passage de la personne en CDI"
            />
          </div>

          <div className={styles.withMarginBottom}>
            <MaxMobilityFields
              control={control}
              maxMobilityDistance={maxMobilityDistance}
              maxMobilityTime={maxMobilityTime}
              isDisplayMode={candidateR2Data?.isClosed}
            />
          </div>

          <WithLightTitle title="flexibilité horaires">
            <div className={styles.cardContainer}>
              {hoursPrioritiesController.fields.map((hoursPriority, idx) => (
                <HourCard
                  isDisplayMode={candidateR2Data?.isClosed}
                  className={styles.margin}
                  key={hoursPriority.hours}
                  text={hoursPriority.hours ?? ''}
                  priority={hoursPriority.priority ?? R1Priority.Neutre}
                  onPriorityChanged={priority => {
                    hoursPrioritiesController.update(idx, {
                      hours: hoursPriority.hours,
                      priority: priority as R1Priority,
                    });
                  }}
                />
              ))}
            </div>
          </WithLightTitle>

          <div className={styles.separator} />

          <div className={styles.withMarginBottom}>
            <JobsSelection
              isLoading={isLoadingCandidateR2 || isFetchingCandidateR2}
              isSuccessful={isCandidateR2Success && !isFetchingCandidateR2}
              isDisplayMode={candidateR2Data?.isClosed ?? false}
              jobs={jobs}
              selectedJobs={selectedJobsController.field.value}
              otherJobs={otherJobsController.field.value}
              mainQualification={mainQualificationIdController.field.value}
              setJobs={jobsController.field.onChange}
              setSelectedJobs={selectedJobsController.field.onChange}
              setOtherJobs={otherJobsController.field.onChange}
              setMainQualification={mainQualificationIdController.field.onChange}
            />
          </div>

          <WithLightTitle title="montant GMMR / mois" className={styles.gmmr}>
            <NumberInputDeprecatedWithDisplayMode
              control={control}
              name="gmmr"
              rightIcon={<EuroSign />}
              type="number"
              isDisplayMode={candidateR2Data?.isClosed}
            />
          </WithLightTitle>
          {canModifyR2 && (
            <div className={styles.buttonGroup}>
              <FetchButton
                secondary
                title="enregistrer la conclusion"
                errorTitle="réessayer"
                fetchStatus={
                  buttonType === ButtonType.VALIDATE && !isSubmitting
                    ? updateFetchStatus
                    : FETCH_STATUS.FULFILLED
                }
                onClick={() => validationHandleSubmit()}
                onSuccess={() => {
                  if (buttonType === ButtonType.VALIDATE) toast.success('Votre R2 a été modifié');
                }}
                onError={() => {
                  if (buttonType === ButtonType.VALIDATE) {
                    toast.error("Erreur lors de l'enregistrement de votre R2");
                  }
                }}
              />

              <FetchButton
                title="finaliser le R2"
                errorTitle="réessayer"
                fetchStatus={
                  buttonType === ButtonType.FINISH && !isSubmitting
                    ? finishStatus
                    : FETCH_STATUS.FULFILLED
                }
                onClick={() => validationHandleSubmit({ finish: true })}
                onSuccess={() => {
                  if (buttonType === ButtonType.FINISH) setValidationModal(true);
                }}
                onError={() => {
                  if (buttonType === ButtonType.FINISH) {
                    toast.error("Erreur lors de l'envoie de votre R2");
                  }
                }}
              />
            </div>
          )}
          {(errorMessage.length > 0 || Object.keys(formState.errors).length !== 0) && (
            <Message.Error>
              <>
                <div className={styles.errorMessage}>
                  il y a des erreurs ou des champs non remplis dans le formulaire
                </div>
                {errorMessage.map(error => (
                  <ul key={error.key} className={styles.errorMessage}>
                    {error}
                  </ul>
                ))}
                <div ref={messagesEndRef} />
              </>
            </Message.Error>
          )}

          <InterviewValidationModal
            open={validationModal}
            onClose={() => {
              setValidationModal(false);
              openCandidateFile({ candidateId: candidateR2Data?.candidateId });
            }}
          />
          <InterviewRefusalModal
            open={refusalModal}
            onClose={() => setRefusalModal(false)}
            fetchStatus={finishStatus}
            onSuccess={() => {
              setRefusalModal(false);
              openCandidateFile({ candidateId: candidateR2Data?.candidateId });
            }}
            onValidate={() =>
              candidateR2Data &&
              candidateR2Close.mutate({
                candidateR2: {
                  ...candidateR2Data,
                  hoursPriorities: watch('hoursPriorities'),
                  maxMobility: {
                    maxMobilityDistance: parseFloat(watch('maxMobility.maxMobilityDistance') ?? ''),
                    maxMobilityTime: parseFloat(watch('maxMobility.maxMobilityTime') ?? ''),
                  },
                  validation: {
                    validation: watch('validation'),
                    comment: watch('comment'),
                    gmmr: watch('gmmr') ? parseFloat(watch('gmmr') ?? '') : undefined,
                    isCDI: watch('isCDI'),
                  },
                },
              })
            }
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default R2Validation;
