const capitalizeNames = (name: string): string => {
  const particles = ['DE', 'DU', 'DES', 'LE', 'LA', 'LES', "D'", "L'"];
  const lowerCaseParticles = particles.map(p => p.toLowerCase());

  const wordsAndSeparators = name.split(/(\s|-)/);

  const formattedName = wordsAndSeparators
    .map(word => {
      if (word === ' ' || word === '-') {
        return word;
      }

      const upperWord = word.toUpperCase();
      if (upperWord.startsWith("D'") || upperWord.startsWith("L'")) {
        return (
          upperWord.charAt(0).toLowerCase() +
          upperWord.charAt(1) +
          upperWord.substring(2).toLowerCase().charAt(0).toUpperCase() +
          upperWord.substring(3).toLowerCase()
        );
      }
      if (particles.includes(upperWord)) {
        return lowerCaseParticles[particles.indexOf(upperWord)];
      }
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join('');

  return formattedName;
};

export function formatName(person: undefined): undefined;
export function formatName(person: { firstName: string; name?: string; lastName?: string }): string;
export function formatName(person: { firstName?: string; name: string; lastName?: string }): string;
export function formatName(person: { firstName?: string; name?: string; lastName: string }): string;
export function formatName(person: {
  firstName?: string;
  name?: string;
  lastName?: string;
}): string | undefined;
export function formatName(
  person:
    | {
        firstName?: string;
        name?: string;
        lastName?: string;
      }
    | undefined
): string | undefined;
export function formatName(
  person:
    | {
        firstName?: string;
        name?: string;
        lastName?: string;
      }
    | undefined
): string | undefined {
  if (!person) return;
  if (!person.firstName && !person.name && !person.lastName) return;
  return capitalizeNames(
    [person.firstName, person.name || person.lastName].filter(Boolean).join(' ')
  );
}
