import { License, OtherHorizontal } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  AnalyseATTemps,
  ClientsBlue,
  Contrats,
} from '@randstad-lean-mobile-factory/react-assets/dist/pictos';
import {
  Avatar,
  Button,
  CardClickable,
  Checkbox,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';

import { CANDIDATE_STATUS_LABEL } from 'src/Containers/CandidateResults/CandidateResults.types';
import { formatCity } from 'src/Utils/formatCity';
import { formatName } from 'src/Utils/formatName';

import ScoringIndicator from '../../ScoringIndicator';
import { CandidateActions } from '../CandidateActions';
import { CandidateCardProps } from '../CandidateCard.types';

import styles from './VerticalCandidateCard.module.scss';

export const VerticalCandidateCard = ({
  candidate,
  isSelected,
  isSelecting,
  onClick,
  attachCandidate,
}: Omit<CandidateCardProps, 'vertical'>) => {
  const contractEndDate = moment().isBefore(candidate.availabilityDate)
    ? moment(candidate.availabilityDate)
    : undefined;

  return (
    <CardClickable color="white" className={styles.card} onClick={onClick} focus={isSelected}>
      <div
        className={classnames(
          styles.header,
          candidate.candidateStatus === CANDIDATE_STATUS_LABEL['Intérimaire']
            ? styles.navy
            : candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim']
            ? styles.yellow
            : styles.greenish
        )}
      >
        {isSelecting && (
          <div className={styles.checkbox}>
            <Checkbox checked={isSelected} />
          </div>
        )}
        <Avatar
          size="medium"
          color={
            candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'] ? 'white' : 'blue'
          }
          content={candidate}
        />
        <div
          data-theme={
            candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'] ? 'light' : 'dark'
          }
        >
          <div className={styles.name}>{formatName(candidate)}</div>
          <div className={styles.qualification}>{candidate.mainQualification?.label}</div>
          <div className={styles.address}>{formatCity(candidate)}</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.additionalInfo}>
          <div className={styles.status}>
            {candidate.candidateStatus &&
              {
                [CANDIDATE_STATUS_LABEL['Intérimaire']]: 'intérimaire',
                [CANDIDATE_STATUS_LABEL['CDI Interim']]: 'CDI intérimaire',
                [CANDIDATE_STATUS_LABEL['Candidat évalué']]: 'candidat',
                [CANDIDATE_STATUS_LABEL['Candidat']]: 'candidat',
              }[candidate.candidateStatus]}
          </div>
          {candidate.scoring && (
            <div className={styles.scoring}>
              <ScoringIndicator scoring={candidate.scoring} />
              pertinence
            </div>
          )}
        </div>
        <hr />
        {contractEndDate && (
          <>
            <div className={styles.contract}>
              <License color="blue" />
              en contrat (fin: {contractEndDate.format('DD/MM/YYYY')})
            </div>
            <hr />
          </>
        )}
        <div className={styles.stats}>
          <div className={styles.stat}>
            <AnalyseATTemps size="large" />
            <div>{candidate.nbHours}</div>
            <div>hrs</div>
          </div>
          <div className={styles.stat}>
            <ClientsBlue size="large" />
            <div>{candidate.nbCompany}</div>
            <div>clients</div>
          </div>
          <div className={styles.stat}>
            <Contrats size="large" />
            <div>{candidate.nbMissions}</div>
            <div>contrats</div>
          </div>
        </div>
        <hr />
      </div>
      <CandidateActions
        trigger={
          <Button variant="secondary" size="medium">
            <OtherHorizontal variant="fill" />
            actions
          </Button>
        }
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        candidate={candidate}
        isSelecting={isSelecting}
        attachCandidate={attachCandidate}
      />
    </CardClickable>
  );
};
