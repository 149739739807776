import { NumberInputDeprecated } from '@randstad-lean-mobile-factory/react-form-fields';
import { Props } from '@randstad-lean-mobile-factory/react-form-fields/dist/Components/_DEPRECATED/NumberInputDeprecated/NumberInputDeprecated.types';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { OwnProps } from './NumberInputDeprecatedWithDisplayMode.types';

/** @deprecated replace with `<TextInputWithDisplayMode type="number" />` which requires you to handle a number instead of a string */
function NumberInputDeprecatedWithDisplayMode<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({ isDisplayMode, ...props }: OwnProps<TFieldValues, TName>) {
  const controller = useController({ name: props.name, control: props.control });
  return (
    <>
      {isDisplayMode ? (
        <>{controller.field.value || "champ non rempli lors de l'entretien"}</>
      ) : (
        <NumberInputDeprecated {...(props as Props<TFieldValues, TName>)} />
      )}
    </>
  );
}

export default NumberInputDeprecatedWithDisplayMode;
