import { ReactComponent as AlimentaireSvg } from './Alimentaire.svg';
import { ReactComponent as AutomobileSvg } from './Automobile.svg';
import { ReactComponent as AutreSvg } from './Autre.svg';
import { ReactComponent as BTPSvg } from './BTP.svg';
import { ReactComponent as HotellerieSvg } from './Hotellerie.svg';
import { ReactComponent as LogistiqueSvg } from './Logistique.svg';
import { ReactComponent as PharmaceutiqueSvg } from './Pharmaceutique.svg';
import { ReactComponent as ServiceSvg } from './Service.svg';

/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Alimentaire = AlimentaireSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Automobile = AutomobileSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Autre = AutreSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const BTP = BTPSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Hotellerie = HotellerieSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Logistique = LogistiqueSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Pharmaceutique = PharmaceutiqueSvg;
/** @deprecated use \@randstad-lean-mobile-factory/react-assets instead */
export const Service = ServiceSvg;
