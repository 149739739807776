export function getEditionRoute({
  candidateId,
  section,
}: {
  candidateId?: string;
  section: string;
}) {
  return `/candidate/${candidateId}/file/${section}`;
}

export interface CandidateRoute {
  route: string;
  component: React.FC<unknown>;
  routerParameters?: {
    candidateId: string;
    firstSection?: string;
    secondSection?: string;
  };
}

export function getRecruitingEditionRoute({
  candidateId,
  section,
  id,
}: {
  candidateId?: string;
  section: string;
  id?: string;
}) {
  return `/candidate/${candidateId}/recruit/${section}/${id}`;
}

export interface CandidateRecruitingRoute {
  route: string;
  component: React.FC<unknown>;
  routerParameters?: {
    candidateId: string;
    id: string;
    firstSection?: string;
    secondSection?: string;
  };
}

export function getR1CandidateRoute({ candidateId, R1Id }: { candidateId: string; R1Id?: string }) {
  return `/candidate/${candidateId}/recruit/R1/${R1Id}/context`;
}

export function getR2CandidateRoute({ candidateId, R2Id }: { candidateId: string; R2Id?: string }) {
  return `/candidate/${candidateId}/recruit/R2/${R2Id}/context`;
}

export function getFlashMissionUrl(missionId: string) {
  return `${process.env.REACT_APP_FLASH_PUBLIC_URL}/activity/missions/${missionId}`;
}
