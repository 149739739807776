import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CompanySearchResult } from 'src/Containers/Selects/SelectCompany/SelectCompany.types';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, WishPriority } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchCompanies = (
  extraOptions?: UseMutationOptions<CompanySearchResult[], unknown, string>
) => {
  const currentBrand = useSelector(getCurrentBrand);

  return useMutation(
    [MutationKeys.fetchCompany],
    async (keyword: string) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        throw new Error('No current brand or has an invalid current brand');
      }

      const companies = await CompaniesService.companiesControllerSearchCompany({
        body: { nameOrSiret: keyword, agencyIds: currentBrand.agencies },
      });

      const results = companies.map(company => {
        return {
          identifier: company.companyId ?? '',
          label: company.companyName ?? '',
          siret: company.siret ?? '',
          wishPriority: WishPriority.Obligatoire,
          companyAddress: company.companyAddress,
        };
      });

      return results;
    },
    { ...extraOptions }
  );
};

export const useFetchCompaniesWithThrottle = () => {
  const companiesMutation = useFetchCompanies();

  return useMemo(
    () => ({
      ...companiesMutation,
      mutate: debounce(companiesMutation.mutate, 500),
    }),
    [companiesMutation]
  );
};
