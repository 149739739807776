import 'moment/locale/fr';
import moment from 'moment';

import { OSMCommonModelDateDto } from 'src/Services/API';

moment.locale('fr');

export function toDate(date: OSMCommonModelDateDto | undefined): Date | undefined {
  if (date === undefined) {
    return undefined;
  }
  return new Date(
    date.year || new Date().getUTCFullYear(),
    (date.month || new Date().getUTCMonth() + 1) - 1,
    date.day
  );
}
