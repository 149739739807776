import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Badge,
  Button,
  CardCheckable,
  CardContent,
  Loader,
  Message,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useIsMutating } from '@tanstack/react-query';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';

import { useAddQualificationToCandidate } from 'src/Hooks/Candidates/useAddQualificationToCandidate';
import { useFetchCandidateBusinesses } from 'src/Hooks/Candidates/useFetchCandidateBusinesses';
import { useFetchCandidateExperiences } from 'src/Hooks/Candidates/useFetchCandidateExperiences';
import { useFetchCandidateSkills } from 'src/Hooks/Candidates/useFetchCandidateSkills';
import { useFetchCandidateSuggestedQualifications } from 'src/Hooks/Candidates/useFetchCandidateSuggestedQualifications';
import { MutationKeys } from 'src/Hooks/types';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Qualifications.module.scss';
import { QualificationProps } from './Qualifications.types';

export const Qualifications = ({ control, candidateId }: QualificationProps) => {
  const businessSkills = useFetchCandidateBusinesses(candidateId);
  const {
    data: skills,
    isLoading: isSkillsLoading,
    refetch: refetchSkills,
  } = useFetchCandidateSkills(candidateId);
  const {
    data: suggestedQualifications,
    isLoading: isSuggestedQualificationsLoading,
    refetch: refetchSuggestedQualifications,
  } = useFetchCandidateSuggestedQualifications(candidateId);
  const {
    data: experiences,
    isLoading: isExperiencesLoading,
    refetch: refetchExperiences,
  } = useFetchCandidateExperiences(candidateId);

  const addQualificationToCandidate = useAddQualificationToCandidate(candidateId, {
    onError: (error, body, context) => {
      field.onChange(
        field.value.filter(qualificationId =>
          context?.previousSkills?.qualifications?.some(
            qualification => qualification.id === qualificationId
          )
        )
      );
      field.onBlur();
      toast.error("désolé, une erreur s'est produite");
    },
  });
  const isAdding = useIsMutating({
    mutationKey: [MutationKeys.addQualificationToCandidate, candidateId],
  });

  const refetch = useCallback(() => {
    if (!skills) refetchSkills();
    if (!suggestedQualifications) refetchSuggestedQualifications();
    if (!experiences) refetchExperiences();
  }, [
    skills,
    experiences,
    refetchSkills,
    refetchExperiences,
    refetchSuggestedQualifications,
    suggestedQualifications,
  ]);

  const qualifications = useMemo(
    () =>
      skills?.qualifications?.map(qualification => {
        const relatedExperiences =
          experiences?.filter(experience => experience.qualification?.id === qualification.id) ??
          [];
        const experience = relatedExperiences.reduce(
          (acc, experience) => acc + moment(experience.endDate).diff(experience.startDate, 'days'),
          0
        );
        const skills =
          businessSkills.data?.find(skill =>
            skill.candidateQualifications?.find(q => q.qualificationId === qualification.id)
          )?.candidateBusinessesSkills?.length ?? 0;
        return { ...qualification, skills, experience };
      }),
    [skills?.qualifications, experiences, businessSkills.data]
  );

  const { field, fieldState } = useController({ control, name: 'qualifications' });

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {`sur ${pluralFormat(field.value.length, 'quelle', false)} 
        ${pluralFormat(
          field.value.length,
          'qualification',
          false
        )} souhaitez-vous créer le cv randstad ?`}
      </h3>
      {isSkillsLoading || isSuggestedQualificationsLoading || isExperiencesLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des qualifications en cours
        </div>
      ) : !qualifications || !suggestedQualifications ? (
        <div className={styles.illustration}>
          <ServerErrorYellow />
          désolé, une erreur s'est produite lors du chargement des qualifications
          <Button type="button" onClick={refetch}>
            réessayer
          </Button>
        </div>
      ) : (
        <>
          {fieldState.error && (
            <Message.Warning>
              <ul style={{ marginLeft: '1rem' }}>
                <li>{fieldState.error.message}</li>
              </ul>
            </Message.Warning>
          )}
          <div>qualifications du dossier randstad</div>
          {!qualifications.length ? (
            <div className={styles.illustration}>
              <VidePasteque />
              aucune qualification trouvée
            </div>
          ) : (
            <div className={styles.grid}>
              {qualifications.map(qualification => (
                <CardCheckable
                  key={qualification.id}
                  color="beige"
                  checked={field.value.includes(qualification.id)}
                  onChange={checked => {
                    if (checked) {
                      field.onChange([...field.value, qualification.id]);
                    } else {
                      field.onChange(
                        field.value.filter(qualificationId => qualificationId !== qualification.id)
                      );
                    }
                    field.onBlur();
                  }}
                >
                  <CardContent
                    title={qualification.label}
                    supportingLines={[
                      [
                        qualification.main && 'qualification principale',
                        qualification.experience > 30
                          ? `${moment
                              .duration(qualification.experience, 'days')
                              .as('months')
                              .toFixed()} mois`
                          : qualification.experience > 0
                          ? pluralFormat(qualification.experience, 'jour')
                          : undefined,
                      ]
                        .filter(Boolean)
                        .join(' | '),
                    ].filter(Boolean)}
                  />
                  {qualification.skills > 0 && (
                    <Badge color="blue">{pluralFormat(qualification.skills, 'compétence')}</Badge>
                  )}
                </CardCheckable>
              ))}
            </div>
          )}
          {suggestedQualifications.length > 0 && (
            <>
              <div>
                autres qualifications identifiées dans ses contrats randstad. En les sélectionnant,
                vous les intégrez au dossier du talent
              </div>
              <div className={styles.grid}>
                {suggestedQualifications?.map(qualification => (
                  <CardCheckable
                    color="beige"
                    checked={false}
                    onChange={() => {
                      field.onChange([...field.value, qualification.id]);
                      field.onBlur();
                      addQualificationToCandidate.mutate(qualification.id);
                    }}
                    disabled={isAdding > 0}
                  >
                    <CardContent title={qualification.label} />
                  </CardCheckable>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
