import { useQuery, useQueryClient } from '@tanstack/react-query';
import invariant from 'invariant';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

import { usePresearchMissions } from './useSearchMissions';

export const useFetchMissionDetails = (missionId?: string) => {
  const brandCode = useSelector(getBrandCode);

  const presearchMissions = usePresearchMissions();

  return useQuery(
    [QueryKeys.fetchMissionDetails, missionId],
    async () => {
      invariant(missionId, 'missionId is missing');
      const missionDetails = await MissionsService.missionsControllerGetMission({
        missionId,
        brandCode,
      });
      presearchMissions(missionDetails.company?.companyId, missionDetails.qualification?.id);
      return missionDetails;
    },
    { enabled: missionId !== undefined }
  );
};

export const usePrefetchMissionDetails = () => {
  const brandCode = useSelector(getBrandCode);

  const presearchMissions = usePresearchMissions();

  const queryClient = useQueryClient();

  return useCallback(
    (missionId: string) =>
      queryClient.prefetchQuery([QueryKeys.fetchMissionDetails, missionId], async () => {
        invariant(missionId, 'missionId is missing');
        const missionDetails = await MissionsService.missionsControllerGetMission({
          missionId,
          brandCode,
        });
        presearchMissions(missionDetails.company?.companyId, missionDetails.qualification?.id);
        return missionDetails;
      }),
    [brandCode, presearchMissions, queryClient]
  );
};
