import { TextInput, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getFirstName, getLastName } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';

import styles from './NameSearch.module.scss';

export const NameSearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);

  const onFirstNameChange = useCallback(
    (value: string) => dispatch(searchActions.setFirstName(value)),
    [dispatch]
  );
  const onLastNameChange = useCallback(
    (value: string) => dispatch(searchActions.setLastName(value)),
    [dispatch]
  );

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        if (lastName && lastName.length >= 2) history.push('/results');
      }}
    >
      <WithLightTitle title={'nom'}>
        <TextInput
          value={lastName}
          onChange={event => onLastNameChange((event.target as HTMLInputElement).value)}
          placeholder="saisir un nom de famille (2 caractères mini)"
        />
      </WithLightTitle>
      <div className={styles.separator} />
      <WithLightTitle title={'prénom'}>
        <TextInput
          value={firstName}
          onChange={event => onFirstNameChange((event.target as HTMLInputElement).value)}
          placeholder="saisir un prénom (2 caractères mini)"
        />
      </WithLightTitle>
      <button type="submit" className={styles.hide} />
    </form>
  );
};
