import { Cards, Chain, Flux, List } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  Checkbox,
  ChipGroupItem,
  ExclusiveChipGroup,
  Link,
  Loader,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CandidateCard } from 'src/Components/CandidateCard';
import RattachementRecapModal from 'src/Containers/Modals/RattachementRecapModal/RattachementRecapModal.component';
import { useFetchMatchingCandidates } from 'src/Hooks/Matching';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { getSelectedMissionId } from 'src/Redux/Missions/Selectors';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { MatchingResult } from 'src/Services/API';
import { getFlashMissionUrl } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import SearchResultsSidebar from '../SearchResultsSidebar';

import styles from './CandidateResults.module.scss';

export const CandidateResults = () => {
  const dispatch = useDispatch();
  const selectedMissionId = useSelector(getSelectedMissionId);
  const displayMode = useSelector(getCardDisplayMode);

  const { data, isLoading, refetch } = useFetchMatchingCandidates();

  const [isRecapModalOpen, setIsRecapModalOpen] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<MatchingResult[]>([]);

  const { openCandidateFile } = useOpenCandidateFile();

  const isSelectionModeEnabled = !!selectedMissionId;

  const candidates = useMemo(
    () => data?.sort((a, b) => (a.firstName && b.firstName && a.firstName < b.firstName ? -1 : 1)),
    [data]
  );

  return (
    <div className={styles.container}>
      <SearchResultsSidebar />
      <div className={styles.content}>
        <div className={styles.titleBar}>
          <div className={styles.title}>
            {selectedMissionId ? (
              <>
                {data ? pluralFormat(data.length, 'résultat') : 'résultats'} pour votre commande{' '}
                {selectedMissionId}
                <Link
                  onClick={() => window.open(getFlashMissionUrl(selectedMissionId))}
                  onAuxClick={event => {
                    event.preventDefault();
                    window.open(getFlashMissionUrl(selectedMissionId));
                  }}
                  size="small"
                >
                  <Chain size="xsmall" />
                  voir la commande flash
                </Link>
              </>
            ) : (
              `${data ? pluralFormat(data.length, 'résultat') : 'résultats'} pour votre recherche`
            )}
          </div>
          <ExclusiveChipGroup
            value={displayMode}
            onValueChange={displayMode =>
              dispatch(userPreferencesActions.setCardDisplayMode(displayMode))
            }
            showCheckMark
          >
            <ChipGroupItem value={DISPLAY_MODE.GRID}>
              <Cards />
            </ChipGroupItem>
            <ChipGroupItem value={DISPLAY_MODE.LIST}>
              <List />
            </ChipGroupItem>
          </ExclusiveChipGroup>
        </div>
        {isSelectionModeEnabled && (
          <div className={styles.toolBar}>
            <Checkbox
              checked={
                !candidates?.length
                  ? false
                  : candidates.every(candidate => selectedCandidates.includes(candidate))
              }
              halfChecked={candidates?.some(candidate => selectedCandidates.includes(candidate))}
              onChange={() => {
                if (!candidates) return;
                if (candidates.some(candidate => selectedCandidates.includes(candidate)))
                  setSelectedCandidates([]);
                else setSelectedCandidates(candidates);
              }}
            />
            <Button
              variant="secondary"
              size="medium"
              onClick={() => setIsRecapModalOpen(true)}
              disabled={!selectedCandidates.length}
            >
              <Flux />
              rattacher à la commande
            </Button>
          </div>
        )}
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            recherche de candidats en cours
          </div>
        ) : !candidates ? (
          <div className={styles.illustration}>
            <ServerErrorYellow />
            désolé, une erreur s'est produite
            <Button onClick={() => refetch()}>réessayer</Button>
          </div>
        ) : !candidates.length ? (
          <div className={styles.illustration}>
            <VidePasteque />
            pas de résultats pour votre recherche
          </div>
        ) : (
          <div
            className={
              displayMode === DISPLAY_MODE.GRID ? styles.candidatesGrid : styles.candidatesList
            }
          >
            {candidates.map(candidate => (
              <CandidateCard
                vertical={displayMode === DISPLAY_MODE.GRID}
                key={candidate.candidateId}
                candidate={candidate}
                isSelecting={isSelectionModeEnabled}
                isSelected={selectedCandidates.includes(candidate)}
                onClick={() => {
                  if (isSelectionModeEnabled) {
                    if (selectedCandidates.includes(candidate))
                      setSelectedCandidates(
                        selectedCandidates.filter(c => candidate.candidateId !== c.candidateId)
                      );
                    else setSelectedCandidates(prevState => [...prevState, candidate]);
                  } else {
                    openCandidateFile({
                      candidateId: candidate.candidateId,
                      emitAnalyticsEvent: true,
                    });
                  }
                }}
                attachCandidate={() => {
                  setSelectedCandidates([candidate]);
                  setIsRecapModalOpen(true);
                }}
              />
            ))}
          </div>
        )}
      </div>
      {selectedMissionId && (
        <RattachementRecapModal
          open={isRecapModalOpen}
          onClose={() => setIsRecapModalOpen(false)}
          onValidate={() => setSelectedCandidates([])}
          missionId={selectedMissionId}
          candidates={selectedCandidates}
        />
      )}
    </div>
  );
};
