import { useQuery, useQueryClient } from '@tanstack/react-query';
import invariant from 'invariant';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useSearchMissions = (companyId?: string, qualificationId?: string) => {
  const perimeter = useSelector(getPerimeter);

  const agencies = useMemo(
    () => perimeter?.companies?.map(company => company.agencies ?? []).flat() ?? [],
    [perimeter?.companies]
  );

  return useQuery(
    [QueryKeys.searchMissions, companyId, qualificationId, agencies],
    async () => {
      invariant(companyId, 'companyId is missing');
      invariant(qualificationId, 'qualificationId is missing');
      return await MissionsService.missionsControllerGetMissionsWithoutSchedule({
        body: { companyId, qualificationId, agencies },
      });
    },
    {
      enabled: Boolean(companyId && qualificationId),
    }
  );
};

export const usePresearchMissions = () => {
  const perimeter = useSelector(getPerimeter);

  const agencies = useMemo(
    () => perimeter?.companies?.map(company => company.agencies ?? []).flat() ?? [],
    [perimeter?.companies]
  );

  const queryClient = useQueryClient();

  return useCallback(
    (companyId?: string, qualificationId?: string) => {
      if (companyId && qualificationId)
        queryClient.prefetchQuery(
          [QueryKeys.searchMissions, companyId, qualificationId, agencies],
          async () => {
            return await MissionsService.missionsControllerGetMissionsWithoutSchedule({
              body: { companyId, qualificationId, agencies },
            });
          }
        );
    },
    [agencies, queryClient]
  );
};
