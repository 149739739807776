import {
  FetchButton,
  toast,
  WithLightTitle,
  AddButton,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusOpen, Eye } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import throttle from 'lodash.throttle';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController, useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AutosuggestWithList from 'src/Components/AutosuggestWithList';
import HourCard from 'src/Components/HourCard';
import R1LanguageList from 'src/Components/R1LanguageList';
import TextAreaWithLightTitleWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithLightTitleWithDisplayMode';
import ToggleSwitchWithDisplayMode from 'src/Components/WithDisplayMode/ToggleSwitchWithDisplayMode';
import R1LanguageModal from 'src/Containers/Modals/R1LanguageModal';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useFetchCandidateSkillsFromURL } from 'src/Hooks/Candidates/useFetchCandidateSkillsFromURL';
import { useFetchLastYearCandidateContracts } from 'src/Hooks/Candidates/useFetchLastYearCandidateContracts';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { useUpdateCandidateSkills } from 'src/Hooks/Candidates/useUpdateSkills';
import { useFetchDiplomas } from 'src/Hooks/Diplomas/useFetchDiplomas';
import { useFetchHabilitations } from 'src/Hooks/Habilitations';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { LanguageLevel, InterviewsSectors, R1Hours, R1Priority } from 'src/Services/API';
import { languageIconMapping, sectorIconMapping } from 'src/Services/IconMapping';
import { toFetchStatus } from 'src/Services/ReactQuery';

import MaxMobilityFields from '../../../../Components/MaxMobilityFields';
import JobsSelection from '../../JobsSelection/JobsSelection.component';

import styles from './CandidateInformations.module.scss';
import {
  candidateProfileSchema,
  KeyOfCandidateProfileSchemaTypeQuestion,
} from './CandidateInformations.type';
import ExperienceDisplayer from './ExperienceDisplayer';

const profileTextArea = [
  {
    title:
      'y a-t-il des environnements au sein desquels vous ne souhaitez ou ne pouvez pas travailler ?',
    placeHolder: '(bruit, hauteur, froid, chaleur…)',
    name: 'workingEnvironment' as KeyOfCandidateProfileSchemaTypeQuestion,
  },
  {
    title: 'Situation actuelle : Evaluation du candidat',
    placeHolder:
      'Faites parler le candidat sur sa situation actuelle : quelle est sa perception sur … Type d’emploi, conditions de travail, responsabilités, rémunération, temps complet…',
    name: 'currentSituation' as KeyOfCandidateProfileSchemaTypeQuestion,
  },
];

const defaultHoursPriorities = Object.values(R1Hours).map(hours => ({
  hours,
  priority: hours === 'travail en journée' ? R1Priority.Favorable : R1Priority.Neutre,
}));

const defaultSectorsPriorities = Object.values(InterviewsSectors).map(sector => ({
  sector,
  priority: R1Priority.Neutre,
}));

const CandidateInformations = () => {
  const units = useSelector(getPerimeterUnits);

  const params = useParams<{
    id: string;
  }>();

  /*
  The following part is used to fetch and update informations about the candidate and his R1
  */
  const candidate = useFetchCandidateDetailsFromURL();
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    isSuccess: isSkillsSuccess,
    isFetching: isFetchingSkills,
  } = useFetchCandidateSkillsFromURL();
  const {
    data: candidateR1Data,
    isLoading: isLoadingCandidateR1,
    isSuccess: isCandidateR1Success,
    isFetching: isFetchingCandidateR1,
  } = useFetchCandidateR1FromURL();
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const candidateSkillsUpdate = useUpdateCandidateSkills(candidateR1Data?.candidateId ?? '');
  const candidateLastYearContractsQuery = useFetchLastYearCandidateContracts(
    candidate.data?.id ?? ''
  );
  const r1FetchStatus = toFetchStatus(candidateR1Update);
  const isDisplayMode = useMemo(
    () =>
      candidateR1Data && (candidateR1Data.isClosed || !units.includes(candidateR1Data.agencyName)),
    [candidateR1Data, units]
  );

  /*
  The following part is used for the habilitation, language and diploma research
  */

  const habilitationMutation = useFetchHabilitations();
  const [habilitationKeyword, setHabilitationKeyword] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHabilitationMethod = useCallback(throttle(habilitationMutation.mutate, 500), [
    habilitationMutation.mutate,
  ]);
  const habilitationFetchStatus = toFetchStatus(habilitationMutation);
  const diplomaMutation = useFetchDiplomas();
  const [diplomaKeyword, setDiplomaKeyword] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDiplomaMethod = useCallback(throttle(diplomaMutation.mutate, 500), [
    diplomaMutation.mutate,
  ]);
  const diplomaFetchStatus = toFetchStatus(diplomaMutation);

  const [languageModal, setLanguageModal] = useState(false);

  const candidateLanguages =
    skillsData?.languages?.map(language => ({
      identifier: language.id,
      label: language.label,
      level: language.level,
      icon: languageIconMapping[language.label as keyof typeof languageIconMapping],
    })) ?? [];
  const candidateDiploma = !candidateR1Data?.isClosed
    ? skillsData?.diplomas ?? []
    : candidateR1Data?.diplomas;
  const candidateHabilitations = !candidateR1Data?.isClosed
    ? skillsData?.habilitations ?? []
    : candidateR1Data?.habilitations ?? [];
  const candidateJobs = skillsData?.jobs ?? [];
  const [seeCompanyList, setSeeCompanyList] = useState(false);

  /*
  The following part is link to zod and to the form filling
  */

  const { control, setValue, watch, handleSubmit, formState, reset } = useFormWithZodResolver({
    schema: candidateProfileSchema,
    defaultValues: {
      languages: candidateLanguages,
      selectedJobs:
        skillsData?.jobs?.filter(
          job =>
            candidateR1Data?.selectedJobs?.find(selectedJob => selectedJob.id === job.id) !==
              undefined ||
            (candidate.data?.qualificationId &&
              job.qualifications.includes(candidate.data?.qualificationId))
        ) ?? [],
      otherJobs:
        skillsData?.jobs?.filter(
          job =>
            candidateR1Data?.selectedJobs?.find(selectedJob => selectedJob.id === job.id) ===
              undefined &&
            !(
              candidate.data?.qualificationId &&
              job.qualifications.includes(candidate.data?.qualificationId)
            )
        ) ?? [],
      diploma: candidateDiploma,
      jobs: candidateJobs,
      mainQualification: candidate.data?.qualificationId,
      habilitations: candidateHabilitations,
      hasVehicle: candidateR1Data?.hasVehicle ?? false,
      hasNationalMobility: candidateR1Data?.hasNationalMobility ?? false,
      candidateAgreement: candidateR1Data?.candidateAgreement ?? false,
      workingEnvironment: candidateR1Data?.workingEnvironment,
      currentSituation: candidateR1Data?.currentSituation,
      hoursPriorities:
        // if R1 was created before new hours format then we take new format
        candidateR1Data?.hoursPriorities?.length !== undefined &&
        candidateR1Data?.hoursPriorities.length > 6
          ? candidateR1Data?.hoursPriorities
          : defaultHoursPriorities,
      sectors:
        candidateR1Data?.sectorPriorities && candidateR1Data.sectorPriorities.length
          ? candidateR1Data.sectorPriorities
          : defaultSectorsPriorities,
      maxMobility: {
        maxMobilityDistance: candidateR1Data?.maxMobility?.maxMobilityDistance?.toString(),
        maxMobilityTime: candidateR1Data?.maxMobility?.maxMobilityTime?.toString(),
      },
    },
  });

  const profileHandleSubmit = () =>
    handleSubmit(values => {
      if (!isDisplayMode) {
        if (candidateR1Data) {
          candidateR1Update.mutate({
            ...candidateR1Data,
            hasVehicle: values.hasVehicle,
            hasNationalMobility: values.hasNationalMobility,
            candidateAgreement: values.candidateAgreement,
            workingEnvironment: values.workingEnvironment,
            currentSituation: values.currentSituation,
            hoursPriorities: values.hoursPriorities,
            sectorPriorities: values.sectors,
            selectedJobs: values.selectedJobs.map(job => {
              return {
                id: job.id,
                sectors:
                  candidateR1Data.selectedJobs?.find(selectedJob => selectedJob.id === job.id)
                    ?.sectors ?? [],
              };
            }),
            maxMobility: {
              maxMobilityDistance: values.maxMobility?.maxMobilityDistance
                ? parseFloat(values.maxMobility?.maxMobilityDistance)
                : undefined,
              maxMobilityTime: values.maxMobility?.maxMobilityTime
                ? parseFloat(values.maxMobility?.maxMobilityTime)
                : undefined,
            },
          });
        }
        if (skillsData) {
          candidateSkillsUpdate.mutate({
            languages: values.languages.map(language => ({
              id: language.identifier,
              label: language.label ?? '',
              level: language.level ?? LanguageLevel.OccasionalPractice,
            })),
            diplomas: values.diploma,
            drivingLicences: skillsData.drivingLicences,
            habilitationIds: values.habilitations.map(habilitation => habilitation.id),
            domainIds: skillsData.domains?.map(domain => domain.id ?? ''),
            qualifications: values.jobs
              .map(job => job.qualifications)
              .flat(1)
              .map(qualification => {
                return {
                  id: qualification,
                  main: mainQualificationController.field.value === qualification,
                };
              }),
          });
        }
      }
      reset(values);
    });
  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });

  useEffect(() => {
    if (isSkillsSuccess && isCandidateR1Success && candidate.isSuccess) {
      setValue('mainQualification', candidate.data.qualificationId);
      setValue('languages', candidateLanguages);
      setValue('hasVehicle', candidateR1Data?.hasVehicle ?? false);
      setValue('hasNationalMobility', candidateR1Data?.hasNationalMobility ?? false);
      setValue('candidateAgreement', candidateR1Data?.candidateAgreement ?? false);
      setValue(
        'diploma',
        candidateDiploma?.map(diploma => {
          return { ...diploma, date: diploma.date ? new Date(diploma.date) : undefined };
        }) ?? []
      );
      setValue('habilitations', candidateHabilitations ?? []);
      setValue('workingEnvironment', candidateR1Data?.workingEnvironment);
      setValue('currentSituation', candidateR1Data?.currentSituation);
      setValue('jobs', candidateJobs ?? []);
      setValue(
        'selectedJobs',
        skillsData?.jobs?.filter(
          job =>
            candidateR1Data?.selectedJobs?.find(selectedJob => selectedJob.id === job.id) !==
              undefined ||
            (candidate.data?.qualificationId &&
              job.qualifications.includes(candidate.data?.qualificationId))
        ) ?? []
      );
      setValue(
        'otherJobs',
        skillsData?.jobs?.filter(
          job =>
            candidateR1Data?.selectedJobs?.find(selectedJob => selectedJob.id === job.id) ===
              undefined &&
            !(
              candidate.data?.qualificationId &&
              job.qualifications.includes(candidate.data?.qualificationId)
            )
        ) ?? []
      );
      setValue(
        'hoursPriorities',
        // if R1 was created before new hours format then we take new format
        candidateR1Data?.hoursPriorities?.length !== undefined &&
          candidateR1Data?.hoursPriorities.length > 6
          ? candidateR1Data?.hoursPriorities
          : defaultHoursPriorities
      );
      setValue(
        'sectors',
        candidateR1Data?.sectorPriorities && candidateR1Data?.sectorPriorities.length
          ? candidateR1Data.sectorPriorities
          : defaultSectorsPriorities
      );
      setValue('maxMobility', {
        maxMobilityDistance: candidateR1Data?.maxMobility?.maxMobilityDistance?.toString() ?? '40',
        maxMobilityTime: candidateR1Data?.maxMobility?.maxMobilityTime?.toString() ?? '75',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSkillsSuccess,
    isCandidateR1Success,
    isFetchingSkills,
    candidate.isSuccess,
    candidate.isFetching,
  ]);

  const jobsController = useController({ name: 'jobs', control });
  const languagesController = useController({ name: 'languages', control });
  const hasVehicleController = useController({ name: 'hasVehicle', control });
  const hasNationalMobilityController = useController({
    name: 'hasNationalMobility',
    control,
  });
  const candidateAgreementController = useController({ name: 'candidateAgreement', control });
  const mainQualificationController = useController({ name: 'mainQualification', control });
  const selectedJobsController = useController({ name: 'selectedJobs', control });
  const otherJobsController = useController({ name: 'otherJobs', control });
  const hoursPrioritiesController = useFieldArray({
    name: 'hoursPriorities',
    control,
    keyName: 'key',
  });
  const sectorsPrioritiesController = useFieldArray({
    name: 'sectors',
    control,
    keyName: 'key',
  });
  const habilitationsArrayController = useFieldArray({
    name: 'habilitations',
    control,
    keyName: 'key',
  });
  const diplomaArrayController = useFieldArray({ name: 'diploma', control, keyName: 'key' });
  const maxMobilityDistance = watch('maxMobility.maxMobilityDistance');
  const maxMobilityTime = watch('maxMobility.maxMobilityTime');
  const jobs = watch('jobs');
  useEffect(() => {
    setValue(
      'selectedJobs',
      jobs?.filter(
        job =>
          selectedJobsController.field.value.map(jobItem => jobItem.id)?.includes(job.id) ||
          job.qualifications.includes(mainQualificationController.field.value ?? '')
      ) ?? []
    );
    setValue(
      'otherJobs',
      jobs?.filter(
        job =>
          !selectedJobsController.field.value.map(jobItem => jobItem.id)?.includes(job.id) &&
          !job.qualifications.includes(mainQualificationController.field.value ?? '')
      ) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, mainQualificationController.field.value]);

  /*
  The following part is linked to the global context linked to the r1 form
  */
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: profileHandleSubmit,
      fetchStatus: r1FetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSubmitted,
    isSubmitting,
    isSubmitSuccessful,
    r1FetchStatus,
    isLoadingSkills,
    isSkillsSuccess,
  ]);

  return (
    <>
      <div className={styles.title}>identité</div>
      {(candidate.isLoading || candidate.isFetching) && (
        <ContentLoader height="5rem" width="100%" uniqueKey="names">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
        </ContentLoader>
      )}
      {candidate.isSuccess && !candidate.isFetching && (
        <div className={styles.nameContainer}>
          <WithLightTitle title="nom candidat" className={styles.nameInputContainer}>
            {candidate.data?.name ?? 'non renseigné'}
          </WithLightTitle>
          <WithLightTitle title="prénom candidat" className={styles.nameInputContainer}>
            {candidate.data?.firstName ?? 'non renseigné'}
          </WithLightTitle>
        </div>
      )}
      <div className={styles.separator} />
      <R1LanguageList
        isDisplayMode={candidateR1Data?.isClosed}
        values={languagesController.field.value}
        onChange={languagesController.field.onChange}
        cardContainerClassName={styles.cardsContainer}
        title="langues"
        isFetching={isLoadingSkills || isFetchingSkills}
        isSuccess={isSkillsSuccess && !isFetchingSkills}
        emptyText=""
        singularTitle="langue"
      />
      {!isDisplayMode && (
        <>
          <AddButton
            icon={<PlusOpen className={styles.icon} />}
            text="ajouter une langue"
            onClick={() => setLanguageModal(true)}
          />
          <R1LanguageModal
            open={languageModal}
            onClose={() => setLanguageModal(false)}
            languages={languagesController.field.value}
            onLanguageChange={languagesController.field.onChange}
          />
        </>
      )}
      <div className={styles.separator} />
      <div className={styles.title}>mobilité</div>
      {(isLoadingCandidateR1 || isFetchingCandidateR1) && (
        <ContentLoader height="5rem" width="100%" uniqueKey="hasCar">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="20" />
        </ContentLoader>
      )}
      {isCandidateR1Success && !isFetchingCandidateR1 && (
        <div className={styles.mobilityToggleContainer}>
          <ToggleSwitchWithDisplayMode
            isDisplayMode={isDisplayMode}
            toggleLabel="Véhiculé, autonomie de déplacement"
            onCheckStatusChange={hasVehicleController.field.onChange}
            checked={hasVehicleController.field.value}
          />
        </div>
      )}
      <div className={styles.mobilityToggleContainer}>
        <ToggleSwitchWithDisplayMode
          isDisplayMode={isDisplayMode}
          toggleLabel="Mobilité nationale"
          onCheckStatusChange={hasNationalMobilityController.field.onChange}
          checked={hasNationalMobilityController.field.value}
        />
      </div>
      <WithLightTitle title="ville du domicile" className={styles.cityName}>
        {candidate.data?.city}
      </WithLightTitle>
      {!hasNationalMobilityController.field.value && (
        <MaxMobilityFields
          control={control}
          maxMobilityDistance={maxMobilityDistance}
          maxMobilityTime={maxMobilityTime}
          isDisplayMode={isDisplayMode}
        />
      )}
      <div className={styles.separator} />

      <JobsSelection
        isLoading={isLoadingSkills || isFetchingSkills}
        isSuccessful={isSkillsSuccess && !isFetchingSkills}
        isDisplayMode={isDisplayMode ?? true}
        jobs={jobs}
        selectedJobs={selectedJobsController.field.value}
        otherJobs={otherJobsController.field.value}
        mainQualification={mainQualificationController.field.value}
        setJobs={jobsController.field.onChange}
        setSelectedJobs={selectedJobsController.field.onChange}
        setOtherJobs={otherJobsController.field.onChange}
        setMainQualification={mainQualificationController.field.onChange}
      />

      <div className={styles.separator} />
      <div className={styles.title}>expérience randstad</div>
      <div className={styles.experience}>
        <ExperienceDisplayer
          title="date 1er contrat"
          value={moment(candidateLastYearContractsQuery.data?.firstContractStartDate).format('L')}
        />
        <ExperienceDisplayer
          title="nombre d'heures ces 12 derniers mois"
          value={candidateLastYearContractsQuery.data?.nbPaidHours ?? 0}
        />
        <ExperienceDisplayer
          title="nombre de contrats"
          value={candidateLastYearContractsQuery.data?.nbContracts ?? 0}
        />
        <ExperienceDisplayer
          title="nombre de clients différents"
          value={candidateLastYearContractsQuery.data?.companies?.length ?? 0}
        />
      </div>
      {candidateLastYearContractsQuery.data?.companies?.length && (
        <div>
          <button
            onClick={() => setSeeCompanyList(!seeCompanyList)}
            className={styles.companyListButtonContainer}
          >
            <Eye />
            <span className={styles.companyListButton}>
              {seeCompanyList
                ? 'masquer la liste des clients différents'
                : 'voir la liste des clients différents'}
            </span>
          </button>
        </div>
      )}
      {seeCompanyList &&
        candidateLastYearContractsQuery.data?.companies?.map(company => (
          <div key={company.id} className={styles.companyName}>
            {company.name}
          </div>
        ))}
      <div className={styles.separator} />
      <AutosuggestWithList
        isDisplayMode={isDisplayMode}
        values={diplomaArrayController.fields}
        onChange={diploma =>
          diplomaArrayController.replace(
            diploma.map((diplomaItem, idx) => {
              const mainDiploma = diplomaArrayController.fields.find(
                diploma => diploma.main === true
              );
              return {
                id: diplomaItem.id,
                label: diplomaItem.label,
                obtained: true,
                main:
                  mainDiploma && diploma.map(item => item.id).includes(mainDiploma.id)
                    ? mainDiploma.id === diplomaItem.id
                    : idx === 0,
              };
            })
          )
        }
        dataExtractor={(item: { id: string; label: string }) => {
          return { key: item.id, label: item.label };
        }}
        title="diplômes"
        sectionTitle="diplômes"
        isFetching={isLoadingSkills || isFetchingSkills}
        isSuccess={isSkillsSuccess && !isFetchingSkills}
        emptyText=""
        singularTitle="diplôme"
        cardContainerClassName={styles.cardsContainer}
        autosuggestTitle="saisir un diplôme"
        autosuggestFetchStatus={diplomaFetchStatus}
        results={
          diplomaKeyword !== ''
            ? diplomaMutation.data?.map(diploma => {
                return { id: diploma.identifier, label: diploma.label };
              }) ?? []
            : []
        }
        onSearch={(word: string) => {
          setDiplomaKeyword(word);
          word !== '' && throttledDiplomaMethod(word);
        }}
      />
      <div className={styles.separator} />
      <AutosuggestWithList
        isDisplayMode={isDisplayMode}
        values={habilitationsArrayController.fields}
        dataExtractor={(item: { id: string; label: string }) => {
          return { key: item.id, label: item.label };
        }}
        sectionTitle="habilitations / certifications"
        title="habilitations / certifications"
        isFetching={isLoadingSkills || isFetchingSkills}
        isSuccess={isSkillsSuccess && !isFetchingSkills}
        emptyText=""
        singularTitle="habilitation / certification"
        cardContainerClassName={styles.cardsContainer}
        autosuggestTitle="saisir une habilitation ou une certification"
        autosuggestFetchStatus={habilitationFetchStatus}
        results={
          habilitationKeyword !== ''
            ? habilitationMutation.data?.map(habilitation => {
                return { id: habilitation.identifier, label: habilitation.label };
              }) ?? []
            : []
        }
        onChange={habilitations => {
          habilitationsArrayController.replace(
            // We need to create a brand new object because useFieldArray adds `key` fields on items internally
            // Using .replace with items that come directly from useFieldArray exposes that `key` field which throws a zod error
            habilitations.map(habilitation => ({ id: habilitation.id, label: habilitation.label }))
          );
        }}
        onSearch={(word: string) => {
          setHabilitationKeyword(word);
          word !== '' && throttledHabilitationMethod(word);
        }}
      />
      <div className={styles.separator} />
      <div className={styles.title}>critères de sélection principaux</div>
      <WithLightTitle title="secteurs d'activités acceptés">
        <div className={styles.cardContainer}>
          {sectorsPrioritiesController.fields.map((sectorsPriority, idx) => (
            <HourCard
              key={sectorsPriority.sector}
              isDisplayMode={isDisplayMode}
              text={sectorsPriority.sector ?? ''}
              priority={sectorsPriority.priority ?? R1Priority.Neutre}
              icon={sectorIconMapping[sectorsPriority.sector]}
              onPriorityChanged={priority => {
                sectorsPrioritiesController.update(idx, {
                  sector: sectorsPriority.sector,
                  priority: priority as R1Priority,
                });
              }}
            />
          ))}
        </div>
      </WithLightTitle>

      <WithLightTitle title="horaires acceptés">
        <div className={styles.cardContainer}>
          {hoursPrioritiesController.fields.map((hoursPriority, idx) => (
            <HourCard
              isDisplayMode={isDisplayMode}
              key={hoursPriority.hours}
              text={hoursPriority.hours ?? ''}
              priority={hoursPriority.priority ?? R1Priority.Neutre}
              onPriorityChanged={priority => {
                hoursPrioritiesController.update(idx, {
                  hours: hoursPriority.hours,
                  priority: priority as R1Priority,
                });
              }}
            />
          ))}
        </div>
      </WithLightTitle>
      {profileTextArea.map(question => {
        return (
          <React.Fragment key={question.name}>
            {(isLoadingCandidateR1 || isFetchingCandidateR1) && (
              <ContentLoader height="12rem" width="100%" uniqueKey={question.name}>
                <rect x="2%" y="10" rx="4" ry="4" width="80%" height="160" />
              </ContentLoader>
            )}
            {isCandidateR1Success && !isFetchingCandidateR1 && (
              <TextAreaWithLightTitleWithDisplayMode
                isDisplayMode={isDisplayMode}
                title={question.title}
                placeholder={question.placeHolder}
                control={control}
                name={question.name}
                containerClassName={styles.questionSpace}
                className={styles.question}
              />
            )}
          </React.Fragment>
        );
      })}
      <div className={styles.agreementContainer}>
        {(isLoadingCandidateR1 || isFetchingCandidateR1) && (
          <ContentLoader height="6rem" width="100%" uniqueKey="hasAgreed">
            <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
          </ContentLoader>
        )}
        {isCandidateR1Success && !isFetchingCandidateR1 && (
          <ToggleSwitchWithDisplayMode
            isDisplayMode={isDisplayMode}
            toggleLabel="Connaissance et compréhension du CDII par le candidat : (Ifm, CP, mobilité, 3 métiers, flexibilités horaires, obligation mission, GMMR)"
            onCheckStatusChange={candidateAgreementController.field.onChange}
            checked={candidateAgreementController.field.value}
          />
        )}
      </div>
      {!isDisplayMode && (
        <FetchButton
          title="valider profil candidat"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : r1FetchStatus}
          className={styles.button}
          onClick={profileHandleSubmit()}
          onSuccess={() => toast.success('Votre R1 a été modifié')}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R1")}
        />
      )}
      {Object.keys(formState.errors).length !== 0 && (
        <div className={styles.errorMessage}>
          <p>il y a des erreurs ou des champs non remplis dans le formulaire</p>
        </div>
      )}
    </>
  );
};

export default CandidateInformations;
