import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { SEARCH_MODE } from 'src/Redux/Search/Types';
import { CandidateService, Criteria } from 'src/Services/API';

export const useFetchSearchCriteriasFromEDP = () => {
  const brandCode = useSelector(getBrandCode);
  const dispatch = useDispatch();
  return useMutation(['searchCriteriasFromEDP'], async (id: string) => {
    const criteria = await CandidateService.matchingControllerGetCriteriaFromEdp({
      positionStudyId: id,
      brandCode,
    });
    dispatch(searchActions.changeSearchMode(SEARCH_MODE.BY_CRITERIA));
    dispatch(searchActions.setCriterion(criteria as Criteria));
  });
};
