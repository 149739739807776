import { ActionYellow } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  ServerErrorYellowSmall,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  ComboBox,
  Loader,
  Modal,
  ModalActions,
  ModalContent,
  Radio,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CompanySearchResult } from 'src/Containers/Selects/SelectCompany/SelectCompany.types';
import { useFetchCompaniesWithThrottle } from 'src/Hooks/Company/useFetchCompanies';
import { useSetCriteriaFromMission } from 'src/Hooks/Missions/useFetchMissionCriteria';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { useSearchMissions } from 'src/Hooks/Missions/useSearchMissions';
import { useFetchQualificationsWithThrottle } from 'src/Hooks/Repositories/useFetchQualifications';
import { getSelectedMissionId } from 'src/Redux/Missions/Selectors';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { WishPriority } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './MissionSearchModal.module.scss';
import { MissionSearchModalProps } from './MissionSearchModal.types';

const MissionSearchModalContent = ({ onClose }: MissionSearchModalProps) => {
  const dispatch = useDispatch();
  const selectedMissionId = useSelector(getSelectedMissionId);

  const [company, setCompany] = useState<CompanySearchResult>();
  const [qualification, setQualification] = useState<{ id?: string; label?: string }>();

  const [localMissionId, setLocalMissionId] = useState(selectedMissionId);

  const qualificationThrottledMutation = useFetchQualificationsWithThrottle();
  const companiesThrottledMutation = useFetchCompaniesWithThrottle();
  const {
    data: missions,
    isLoading: missionsLoading,
    refetch,
  } = useSearchMissions(company?.identifier, qualification?.id);

  const { data: selectedMissionDetails, isLoading: selectedMissionLoading } =
    useFetchMissionDetails(selectedMissionId);
  useEffect(() => {
    if (selectedMissionDetails && selectedMissionDetails.company?.companyId) {
      setCompany({
        identifier: selectedMissionDetails.company.companyId,
        label: selectedMissionDetails.company.name,
        siret: selectedMissionDetails.company.siret,
        wishPriority: WishPriority.Obligatoire,
      });
      setQualification({
        id: selectedMissionDetails.qualification?.id,
        label: selectedMissionDetails.qualification?.label,
      });
    }
  }, [selectedMissionDetails]);

  const setCriteriaFromMission = useSetCriteriaFromMission({
    onSuccess: () => {
      setTimeout(() => onClose?.(), 500);
    },
    onError: () => {
      setTimeout(() => setCriteriaFromMission.reset(), 500);
    },
  });

  return (
    <ModalContent header={<ActionYellow />} title="sélection de commande">
      {selectedMissionLoading && selectedMissionId ? (
        <div className={styles.illustration}>
          <Loader size="medium" />
          chargement de la mission en cours
        </div>
      ) : (
        <>
          <WithLightTitle title="choix de la qualification">
            <ComboBox
              id="qualification"
              value={qualification ?? null}
              minLengthToSearch={1}
              keyValueExtractor={(qualification: { id?: string; label?: string }) => ({
                key: qualification.id ?? '',
                value: qualification.label ?? '',
              })}
              placeholder="1 caractère minimum"
              onSearch={searchText =>
                searchText !== '' && qualificationThrottledMutation.mutate(searchText)
              }
              fetchStatus={toFetchStatus(qualificationThrottledMutation)}
              searchResults={
                qualificationThrottledMutation.data?.map(qualification => {
                  return { id: qualification.identifier, label: qualification.label };
                }) ?? []
              }
              onChange={qualification => setQualification(qualification ?? undefined)}
            />
          </WithLightTitle>
          <WithLightTitle title="choix du compte">
            <ComboBox
              id="company"
              value={company ?? null}
              minLengthToSearch={1}
              withSubLabel
              keyValueExtractor={company => ({
                key: company.identifier,
                value: company.label
                  ? `${company.label} (${company.siret ?? 'siret inconnu'})`
                  : '',
                subValue: `${company.companyAddress?.postalCode} - ${company.companyAddress?.city}`,
              })}
              onSearch={searchText =>
                searchText !== '' && companiesThrottledMutation.mutate(searchText)
              }
              fetchStatus={toFetchStatus(companiesThrottledMutation)}
              placeholder="1 caractère minimum"
              searchResults={companiesThrottledMutation.data ?? []}
              onChange={company => setCompany(company ?? undefined)}
            />
          </WithLightTitle>
          {!company || !qualification ? null : missionsLoading ? (
            <div className={styles.illustration}>
              <Loader size="medium" />
              chargement des commandes en cours
            </div>
          ) : !missions ? (
            <div className={styles.illustration}>
              <ServerErrorYellowSmall />
              désolé, une erreur s'est produite
              <Button size="medium" onClick={() => refetch()}>
                réessayer
              </Button>
            </div>
          ) : !missions.length ? (
            <div className={styles.illustration}>
              <VidePasteque />
              aucune commande trouvée pour ce compte et cette qualification
            </div>
          ) : (
            missions.map(mission => {
              return (
                <Radio
                  key={mission.missionId}
                  checked={localMissionId === mission.missionId}
                  onChange={checked => {
                    if (checked) setLocalMissionId(mission.missionId);
                    else setLocalMissionId(undefined);
                  }}
                >
                  <div>
                    <div className={styles.missionId}>
                      {[mission.missionId, mission.company?.name].filter(Boolean).join(' - ')}
                    </div>
                    <div className={styles.missionInfo}>
                      {[
                        mission.qualification?.label,
                        mission.startDate && moment(mission.startDate).format('[début le ] L'),
                      ]
                        .filter(Boolean)
                        .join(' | ')}
                    </div>
                  </div>
                </Radio>
              );
            })
          )}
        </>
      )}
      <ModalActions side="left">
        <Button
          variant="tertiary"
          onClick={() => {
            setCompany(undefined);
            setQualification(undefined);
            setLocalMissionId(undefined);
          }}
        >
          tout réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
        <Button
          variant="primary"
          disabled={localMissionId === selectedMissionId}
          onClick={() => {
            if (localMissionId) {
              setCriteriaFromMission.mutate(localMissionId, {
                onSuccess: (_, missionId) => {
                  dispatch(missionsActions.setMissionId(missionId));
                },
              });
            } else {
              dispatch(missionsActions.setMissionId(undefined));
              onClose?.();
            }
          }}
          mutationStatus={setCriteriaFromMission.status}
        >
          valider
        </Button>
      </ModalActions>
    </ModalContent>
  );
};

export const MissionSearchModal = ({ open, onClose }: MissionSearchModalProps) => (
  <Modal size="medium" open={open} onClose={onClose}>
    <MissionSearchModalContent open={open} onClose={onClose} />
  </Modal>
);
