import { MotsClefs } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { ServerErrorYellowSmall } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  Loader,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useMemo, useState } from 'react';

import { useAttachCandidatesToMission } from 'src/Hooks/Missions/useAttachCandidatesToMission';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { getFlashMissionUrl } from 'src/Services/Routing';
import { formatName } from 'src/Utils/formatName';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './RattachementRecapModal.module.scss';
import { Props } from './RattachementRecapModal.types';

const RattachementRecapModal = ({ open, onClose, onValidate, candidates, missionId }: Props) => {
  const { data, isLoading, refetch } = useFetchMissionDetails(missionId);

  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    if (open) setIsSuccess(false);
  }, [open]);

  const attachCandidatesToMission = useAttachCandidatesToMission(missionId, {
    onSuccess: () => {
      setTimeout(() => setIsSuccess(true), 500);
    },
    onSettled: () => {
      setTimeout(() => attachCandidatesToMission.reset(), 500);
    },
  });

  const candidateIdsNotAttached = useMemo(
    () =>
      candidates
        .map(candidate => candidate.candidateId)
        .filter(Boolean)
        .filter(
          candidateId => !data?.candidates?.some(candidate => candidate.candidateId === candidateId)
        ),
    [candidates, data?.candidates]
  );

  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent header={<MotsClefs />} title="confirmation du rattachement">
        {isLoading ? (
          <div>
            <Loader size="medium" />
            chargement en cours
          </div>
        ) : !data ? (
          <div>
            <ServerErrorYellowSmall />
            désolé, une erreur s'est produite
            <Button onClick={() => refetch()}>réessayer</Button>
          </div>
        ) : (
          <>
            <div>
              {data.company?.name} - {data.qualification?.label}
            </div>
            <div className={styles.lightGrey}>
              {pluralFormat(candidates.length, 'profil sélectionné')}
            </div>
            {candidates.map(candidate => (
              <div key={candidate.candidateId}>
                {[
                  formatName(candidate),
                  candidate.mainQualification?.label,
                  candidate.candidateId &&
                    !candidateIdsNotAttached.includes(candidate.candidateId) &&
                    'déjà rattaché sur la mission',
                ]
                  .filter(Boolean)
                  .join(' - ')}
              </div>
            ))}
          </>
        )}
      </ModalContent>
      <ModalActions side="right">
        {isSuccess ? (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                onClose?.();
                onValidate();
              }}
            >
              continuer
            </Button>
            <Button
              onClick={() => {
                window.open(getFlashMissionUrl(missionId));
                onClose?.();
                onValidate();
              }}
            >
              visualiser la commande
            </Button>
          </>
        ) : (
          <>
            <Button variant="secondary" onClick={onClose}>
              annuler
            </Button>
            <Button
              disabled={isLoading || candidateIdsNotAttached.length === 0}
              onClick={() => attachCandidatesToMission.mutate(candidateIdsNotAttached)}
              mutationStatus={attachCandidatesToMission.status}
            >
              valider
            </Button>
          </>
        )}
      </ModalActions>
    </Modal>
  );
};

export default RattachementRecapModal;
