import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MissionsState } from './Types';

const initialState: MissionsState = {};

export const { reducer: missionsReducer, actions: missionsActions } = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    setMissionId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedMissionId = action.payload;
    },
  },
});
