import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CandidatesService, CandidateSkillsDto } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateCandidateSkills = (
  candidateId: string,
  extraOptions?: UseMutationOptions<void, AxiosError, CandidateSkillsDto>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateCandidateSkills, candidateId],
    async (body: CandidateSkillsDto) => {
      return await CandidatesService.candidatesControllerUpdateCandidateSkills({
        id: candidateId,
        body,
      });
    },
    {
      ...extraOptions,
      onSettled: async (data, error, variables, context) => {
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateR1, candidateId]);
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateSkills, candidateId]);
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateBusinesses, candidateId]);
        await queryClient.invalidateQueries([
          QueryKeys.fetchCandidateSuggestedQualifications,
          candidateId,
        ]);
        await queryClient.invalidateQueries([QueryKeys.fetchCandidateDetails, candidateId]);
        await queryClient.invalidateQueries([
          QueryKeys.fetchCandidateSuggestedQualifications,
          candidateId,
        ]);

        extraOptions?.onSettled?.(data, error, variables, context);
      },
    }
  );
};
