import { Download } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Drawer,
  ModalActions,
  ModalContent,
  Stepper,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { z } from 'zod';

import { useUploadCandidateResume } from 'src/Hooks/Candidates/useUploadCandidateResume';
import { getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';

import { BusinessesSkills } from './BusinessSkills';
import styles from './CandidateResume.module.scss';
import { FormStep, formSchema, formSteps } from './CandidateResume.schema';
import { ConfirmExitModal } from './ConfirmExitModal';
import { Degrees } from './Degrees';
import { Description } from './Description';
import { EmployeeData } from './EmployeeData';
import { Experiences } from './Experiences';
import { Overview } from './Overview/Overview.component';
import { Qualifications } from './Qualifications';
import { UploadSuccessModal } from './UploadSuccessModal';

const CandidateResume = () => {
  const history = useHistory();
  const { candidateId } = useParams<{ candidateId: string }>();
  const currentBrand = useSelector(getCurrentBrand);
  const perimeter = useSelector(getPerimeter);

  useEffect(() => {
    ReactGA.event('START_RESUME_GENERATION', {
      brandCode: currentBrand?.brandCodeApiHeader,
      agency: perimeter?.defaultAgencyId,
      regionId: perimeter?.regionId,
      zoneId: perimeter?.zoneId,
    });
  }, [
    currentBrand?.brandCodeApiHeader,
    perimeter?.defaultAgencyId,
    perimeter?.regionId,
    perimeter?.zoneId,
  ]);

  const uploadCandidateResume = useUploadCandidateResume(candidateId, {
    onSuccess: () => {
      setUploadSuccessModalOpen(true);
    },
    onSettled: () => {
      setTimeout(() => uploadCandidateResume.reset(), 500);
    },
  });

  const { control, trigger, handleSubmit, getFieldState, getValues, clearErrors, watch } =
    useFormWithZodResolver({
      schema: formSchema,
      defaultValues: {
        qualifications: [],
        skills: {
          businessSkills: [],
          behaviourSkills: [],
        },
        experiences: [],
        degrees: {
          diplomas: [],
          tests: [],
          habilitations: [],
          drivingLicences: [],
        },
        employee: {
          name: false,
          identity: false,
          address: false,
          contactInfo: false,
          availability: false,
          other: false,
          otherValue: '',
          isAnonymityConfirmed: false,
        },
        description: '',
      },
    });

  const [formStep, setFormStep] = useState<FormStep>('qualifications');
  const [stepAfterAnonymityConfirmed, setStepAfterAnonymityConfirmed] = useState<FormStep>();
  const stepperStep = useMemo(() => {
    if (['skills', 'experiences', 'degrees'].includes(formStep)) return 'professionalData';
    return formStep;
  }, [formStep]);
  const professionalData = useMemo(() => {
    switch (formStep) {
      case 'skills':
        return 'données professionnelles 1/3';
      case 'experiences':
        return 'données professionnelles 2/3';
      case 'degrees':
        return 'données professionnelles 3/3';
      default:
        return 'données professionnelles';
    }
  }, [formStep]);

  const [confirmExitModalOpen, setConfirmExitModalOpen] = useState(false);
  const [uploadSuccessModalOpen, setUploadSuccessModalOpen] = useState(false);

  const goToStep = useCallback(
    async (nextStep: FormStep) => {
      const prevStepIdx = formSteps.indexOf(formStep);
      const nextStepIdx = formSteps.indexOf(nextStep);
      if (nextStepIdx < prevStepIdx) return setFormStep(nextStep);
      for (const prevStep of formSteps.slice(0, nextStepIdx)) {
        const isSuccess = await trigger(prevStep);
        if (!isSuccess) {
          setStepAfterAnonymityConfirmed(nextStep);
          return setFormStep(prevStep);
        }
      }
      setFormStep(nextStep);
    },
    [formStep, trigger]
  );
  const setStepperStep = useCallback(
    (stepperStep: string) => {
      if (stepperStep !== 'professionalData') goToStep(stepperStep as FormStep);
      else goToStep('skills');
    },
    [goToStep]
  );
  const goToPrev = useCallback(() => {
    const prevStepIdx = formSteps.indexOf(formStep);
    if (prevStepIdx > 0) setFormStep(formSteps[prevStepIdx - 1]);
  }, [setFormStep, formStep]);
  const goToNext = useCallback(() => {
    const prevStepIdx = formSteps.indexOf(formStep);
    if (prevStepIdx < formSteps.length - 1) {
      setFormStep(formSteps[prevStepIdx + 1]);
    } else {
      handleSubmit(values => uploadCandidateResume.mutate(values), console.error)();
    }
  }, [formStep, handleSubmit, uploadCandidateResume]);

  const handleNext = useMemo(
    (): UseFormHandleSubmit<z.infer<typeof formSchema>> =>
      (successCallback, errorCallback) =>
      async event => {
        event?.preventDefault();
        trigger(formStep).then(isSuccess => {
          if (isSuccess) successCallback(getValues(), event);
          else {
            errorCallback?.({ [formStep]: getFieldState(formStep).error }, event);
            if (formStep === 'employee') setStepAfterAnonymityConfirmed('description');
          }
        });
      },
    [trigger, formStep, getValues, getFieldState]
  );

  const close = () => {
    setConfirmExitModalOpen(true);
  };

  const currentStep = useMemo(() => {
    switch (formStep) {
      case 'qualifications': {
        return <Qualifications candidateId={candidateId} control={control} />;
      }
      case 'skills': {
        return <BusinessesSkills candidateId={candidateId} control={control} watch={watch} />;
      }
      case 'experiences': {
        return <Experiences candidateId={candidateId} control={control} watch={watch} />;
      }
      case 'degrees': {
        return <Degrees candidateId={candidateId} control={control} />;
      }
      case 'employee': {
        return (
          <EmployeeData
            candidateId={candidateId}
            control={control}
            clearErrors={clearErrors}
            goToStep={goToStep}
            stepAfterAnonymityConfirmed={stepAfterAnonymityConfirmed}
          />
        );
      }
      case 'description': {
        return <Description control={control} />;
      }
      case 'overview': {
        return <Overview candidateId={candidateId} watch={watch} />;
      }
    }
  }, [candidateId, clearErrors, control, formStep, goToStep, stepAfterAnonymityConfirmed, watch]);

  return (
    <>
      <Drawer onClose={close} open>
        <ModalContent
          title="génération cv randstad"
          description={
            <Stepper
              fields={[
                'qualifications',
                professionalData,
                'informations talent',
                'description',
                'aperçu',
              ]}
              selectedkey={[
                'qualifications',
                'professionalData',
                'employee',
                'description',
                'overview',
              ]}
              selected={stepperStep}
              onSelectionChange={stepperStep => setStepperStep(stepperStep)}
            />
          }
        >
          <form className={styles.form} onSubmit={handleNext(goToNext, console.error)}>
            {currentStep}
          </form>
        </ModalContent>
        {formStep !== 'qualifications' && (
          <ModalActions side="left">
            <Button variant="secondary" onClick={goToPrev}>
              {formStep === 'overview' ? 'modifier les informations de la synthèse' : 'précédent'}
            </Button>
          </ModalActions>
        )}
        <ModalActions side="right">
          {formStep === 'overview' ? (
            <Button
              onClick={handleNext(goToNext, console.error)}
              mutationStatus={uploadCandidateResume.status}
            >
              <Download variant="line" /> télécharger
            </Button>
          ) : (
            <Button onClick={handleNext(goToNext, console.error)}>suivant</Button>
          )}
        </ModalActions>
      </Drawer>

      <ConfirmExitModal
        open={confirmExitModalOpen}
        onClose={() => setConfirmExitModalOpen(false)}
        onConfirm={() => history.push({ pathname: '/results' })}
      />
      <UploadSuccessModal
        open={uploadSuccessModalOpen}
        onClose={() => setUploadSuccessModalOpen(false)}
      />
    </>
  );
};

export default CandidateResume;
