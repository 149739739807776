import {
  Loader,
  ToggleMenu,
  ToggleMenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';

import { SEARCH_MODE } from 'src/Redux/Search/Types';

import CriteriaSearch from '../CriteriaSearch/';
import { NameSearch } from '../NameSearch';

import styles from './SearchCriteriaSelection.module.scss';
import { Props } from './SearchCriteriaSelection.types';

const SearchCriteriaSelection = ({ className, searchMode, loading, changeSearchMode }: Props) => (
  <div className={className}>
    <div className={styles.header}>
      <p className={styles.title}>nouvelle recherche</p>
    </div>
    <div className={styles.separator} />
    <ToggleMenu value={searchMode} onChange={changeSearchMode} className={styles.toggleMenu}>
      <ToggleMenuItem value={SEARCH_MODE.BY_NAME}>par nom / prénom</ToggleMenuItem>
      <ToggleMenuItem value={SEARCH_MODE.BY_CRITERIA}>par famille de critères</ToggleMenuItem>
    </ToggleMenu>
    {loading ? (
      <Loader heightInRem={8} />
    ) : searchMode === SEARCH_MODE.BY_NAME ? (
      <NameSearch />
    ) : (
      <CriteriaSearch />
    )}
  </div>
);

export default SearchCriteriaSelection;
