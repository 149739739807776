import { CandidateInterviewsSummaryStatus } from 'src/Services/API';

export enum DISPLAY_MODE {
  LIST = 'LIST',
  GRID = 'GRID',
}

export enum CARD_FILTERS {
  'uniquement éligibles à emplois francs' = 'uniquement éligibles à emplois francs',
  'masquer les CDIs signés' = 'masquer les CDIs signés',
}

interface UserPreferencesState {
  cardDisplayMode: DISPLAY_MODE;
  dashboardStatus: CandidateInterviewsSummaryStatus[];
  cardFilters: CARD_FILTERS[];
}

export default UserPreferencesState;
