import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { missionsActions } from 'src/Redux/Missions/Slice';

import { useSetCriteriaFromMission } from '../Missions/useFetchMissionCriteria';
import { usePrefetchMissionDetails } from '../Missions/useFetchMissionDetails';

export const useWatchMissionId = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const missionId = useMemo(() => search.get('missionId'), [search]);

  const fetchMissionCriteria = useSetCriteriaFromMission();
  const prefetchMissionDetails = usePrefetchMissionDetails();

  useEffect(() => {
    if (missionId && !fetchMissionCriteria.isLoading) {
      dispatch(missionsActions.setMissionId(missionId));
      search.delete('missionId');
      history.replace({ ...location, search: search.toString() });
      fetchMissionCriteria.mutate(missionId);
      prefetchMissionDetails(missionId);
    }
  }, [
    dispatch,
    fetchMissionCriteria,
    history,
    location,
    missionId,
    prefetchMissionDetails,
    search,
  ]);
};
