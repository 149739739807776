import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { TextInput } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import { Props } from './AddNewTalentTextInputs.types';

const AddNewTalentTextInputs = ({ control, textInputData }: Props) => {
  return (
    <>
      {textInputData.map(textInput => (
        <div key={textInput.name} style={{ gridArea: textInput.name }}>
          <WithLightTitle title={textInput.placeholder}>
            <TextInput
              control={control}
              name={textInput.name as never}
              placeholder={textInput.placeholder}
            />
          </WithLightTitle>
        </div>
      ))}
    </>
  );
};

export default AddNewTalentTextInputs;
