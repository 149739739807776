import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getBrandCode, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useAttachCandidatesToMission = (
  missionId: string,
  extraOptions?: UseMutationOptions<void, AxiosError, string[]>
) => {
  const brandCode = useSelector(getBrandCode);
  const perimeter = useSelector(getPerimeter);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.attachCandidatesToMission],
    async (candidateIds: string[]) => {
      await MissionsService.missionsControllerAttachCandidatesToMission({
        missionId,
        body: { candidateIds, brandCode },
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchMissionDetails, missionId]);
        ReactGA.event('TT Rattaché', {
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
